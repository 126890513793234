import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PreLoader from '../PreLoader'
import ApiService from "../../services/ApiService";
import { toast } from "react-toastify";

function Wishlist() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { userData } = useSelector((state) => state.auth);

    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Items, setItems] = useState([]);

    const getWishList = useCallback(async () => {
        setLoader(true);

        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().POST(`/get-wishlist`, { userid: userData.id });
        console.log("get-wishlist ", responce.data);

        // return;

        if (responce.data.status === "1") {
            setItems(responce.data.userdetails);
        }
        else {
            setItems([]);
        }

        setLoader(false);
    }, [userData.id]);


    const navigateProfilePage = (user_id) => {
        navigate("/view-profile", {
            state: {
                profile_id: user_id,
                page_show_permission: true
            }
        });
    };


    const AddRemoveWishlist = async (user_id) => {

        let new_data = {
            userid: userData.id,
            profile_id: user_id,
        };

        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().POST("/add_remove_wishlist", new_data);
        console.log("add_remove_wishlist ", responce.data);

        if (responce.data.status === '2') {
            toast.success('Successfully remove to wishlist');
            getWishList();
        }

    }


    useEffect(() => {
        getWishList();
    }, []);


    return (<>

        {/* ==== Loader ==== */}
        <PreLoader Loader={Loader} />
        {/* ==== Loader ==== */}


        <div className="connections">

            {Items.map((item, index) => (<>
                <div className="profile" key={index}>
                    <img className="img-fluid me-2" src={item.profile_pic_pro_resize_200_url} alt="" onClick={() => navigateProfilePage(item.user_id)} />
                    <div className="details" style={{ cursor: "auto" }}>
                        <h2>{item.name}</h2>
                        {/* <p>Software Engineer</p> */}
                        <p>{item.login_status == "0" ? (<span className="offline">Offline</span>) : (<span className="online">Online</span>)}</p>

                    </div>
                    <div>
                        <img
                            className="img-fluid"
                            src={'/images/team/Profile-love-icon.svg'}
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() => AddRemoveWishlist(item.user_id)}
                        />
                    </div>
                </div>
            </>))}


            {(Items.length === 0) && (<>
                <div className="profile text-center">
                    <div className="details">
                        <h2>No Data Found!</h2>
                    </div>

                </div>
            </>)}


        </div>

    </>)
}

export default memo(Wishlist);