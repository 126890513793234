

function PreLoader(prop) {
    return (
        <>
            {/*================================= preloader ============= */}
          {prop.Loader &&  <div id="preloader">
                <div className="clear-loading loading-effect">
                    <img src="/images/preloader.png" alt="home-logo" />
                    <div className="spinner" />
                </div>
            </div> }
        </>
    )
}

export default PreLoader