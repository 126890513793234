import { NavLink } from "react-router-dom";
import PreLoader from "../../PreLoader";
import { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import ApiService from "../../../services/ApiService";


import * as yup from "yup";


const FormSchama = yup.object({
    password: yup.string().required("password required").min(8, "password min length should be in 8 characters"),
    confirm_password: yup.string().required("confirm password required").oneOf([yup.ref("password")], "confirm password does not match password"),
});


function NewPassword() {

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const location = useLocation();


    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [UserID, setUserID] = useState("");


    const {
        register,
        handleSubmit,
        reset,
        // setValue,
        // getValues,
        // trigger,
        formState: { errors },
        // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useForm({
        resolver: yupResolver(FormSchama),
        mode: 'all'
    });



    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [PasswordShow, setPasswordShow] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [ConfPasswordShow, setConfPasswordShow] = useState(false);



    const submit = async (data) => {
        try {

            setLoader(true);

            // console.log(data)

            var new_data = {
                userid: UserID,
                password: data.password
            };

            /////////////////// Api call ///////////////////////
            var responce = await new ApiService().POST("/resetPassword", new_data);

            // console.log("email & pass : login details ",responce.data)

            if (responce.data.status === "success") {
                toast.success("Password change successfully");

                navigate("/login", { replace: true });

            } else {

                toast.error(responce.data.message);
            }

            setLoader(false);


        } catch (err) {
            console.log(err);
            setLoader(false);
        }
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        ///////////// page_show_permission Check ///////////////
        if (location.state === null) {
            navigate(-1)
        } else {

            if (location.state.page_show_permission) {

                setUserID(location.state.userID);

            } else {
                navigate(-1)
            }
        }
        ///////////// page_show_permission Check ///////////////

    }, [])

    return (
        <>

            {/* ==== Loader ==== */}
            <PreLoader Loader={Loader} />
            {/* ==== Loader ==== */}



            {/*================================= login*/}
            <section
                className="login-form login-img dark-bg page-section-ptb "
                style={{
                    background: "url(images/pattern/04.png) no-repeat 0 0",
                    backgroundSize: "cover"
                }}
            >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="login-1-form clearfix text-center">
                                <h4 className="title divider-3 text-white">SET NEW PASSWORD</h4>

                                <form onSubmit={handleSubmit(submit)} >

                                    <div className="section-field mb-3">
                                        <div className="field-widget">
                                            {" "}
                                            <i className="glyph-icon flaticon-padlock" />
                                            <input
                                                id="Password"
                                                className="Password"
                                                type={PasswordShow ? "text" : "password"}
                                                placeholder="Password"
                                                {...register("password")}
                                            />

                                            {PasswordShow ? <i className="show-hide-pass fa fa-eye" onClick={() => setPasswordShow(false)} /> : <i className="show-hide-pass fa fa-eye-slash" onClick={() => setPasswordShow(true)} />}

                                            <p style={{ color: "red" }} className="form-field-error">
                                                {errors.password?.message}
                                            </p>
                                        </div>
                                    </div>


                                    <div className="section-field mb-3">
                                        <div className="field-widget">
                                            {" "}
                                            <i className="glyph-icon flaticon-padlock" />
                                            <input
                                                id="Confirm_Password"
                                                className="Password"
                                                type={ConfPasswordShow ? "text" : "password"}
                                                placeholder="Confirm Password"
                                                {...register("confirm_password")}
                                            />

                                            {ConfPasswordShow ? <i className="show-hide-pass fa fa-eye" onClick={() => setConfPasswordShow(false)} /> : <i className="show-hide-pass fa fa-eye-slash" onClick={() => setConfPasswordShow(true)} />}

                                            <p style={{ color: "red" }} className="form-field-error">
                                                {errors.confirm_password?.message}
                                            </p>
                                        </div>
                                    </div>


                                    <div className="clearfix" />
                                    <div className="section-field text-uppercase text-center mt-2">
                                        {" "}
                                        <button type="submit"
                                            className="button  btn-lg btn-theme full-rounded animated right-icn"
                                        >
                                            <span>
                                                Submit
                                                <i
                                                    className="glyph-icon flaticon-hearts"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        </button>{" "}
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*================================= login*/}

            {/* <Footer /> */}

        </>
    )
}

export default memo(NewPassword)