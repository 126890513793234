import React, { memo, useEffect, useState } from 'react'
import ApiService from '../../services/ApiService';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import moment from 'moment';

function ChatPackages() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { userData } = useSelector(state => state.auth);

    const [Packages, setPackages] = useState([]);

    const [SelectPackages, setSelectPackages] = useState(null);



    const navigate = useNavigate();

    const getAllChatPackages = async () => {
        /////////////////// Api call ///////////////////////
        var response = await new ApiService().GET(`/get-all-chat-membership-plan`);
        console.log("get-all-chat-membership-plan ", response.data);

        if (response.data.status === '1') {
            setPackages(response.data.data);
        }

        chkSelectChatPlan();
    };


    const chkSelectChatPlan = async () => {
        /////////////////// Api call ///////////////////////
        var response = await new ApiService().GET(`/check-user-chat-plan-status/${userData.id}`);
        console.log("check-user-chat-plan-status ", response.data);

        if (response.data.status === '1') {
            setSelectPackages(response.data);
        }

    }


    const chooseAmount = (amount, chat_plan_id) => {
        navigate("/payment", {
            state: {
                payment_type: "recharge",
                amount,
                chat_plan_id
            }
        })
    };


    const ExpireNow = (unixTimestamp) => {

        const expirationDate = moment.unix(unixTimestamp);
        // const difference = moment.duration(expirationDate.diff(moment()));
        // const formattedDifference = moment.duration(difference).humanize();

        const currentDate = moment();


        if (expirationDate.isBefore(currentDate)) {
            return "chat subscription has expired.";
        }
        else {
            // const remainingDays = expirationDate.diff(currentDate, 'days').humanize();
            // return remainingDays;

            const durationUntilExpiration = moment.duration(expirationDate.diff(moment()));
            const humanizedExpiration = durationUntilExpiration.humanize(true);
            return humanizedExpiration;
        }

    }


    useEffect(() => {
        getAllChatPackages();
    }, []);


    return (<>
        <div className="packages">
            <h2 className="text-accent">Chat Package Plans</h2>
            <p className="fs-500">plans that work for everyone</p>
            <div className="row">

                {Packages.map((item, index) => (<>
                    <div key={"chat_package_" + index} className="col-md-4">
                        <div className={`plan ${SelectPackages?.chat_plan_id == item.id ? "plan--accent" : "plan--light"}`}>


                            {/* ///////////// % off /////////////////////////// */}
                            {/* {item.validity === "weekly" &&
                                <p className='active_btn'> 100%</p>
                            } */}

                            {item.validity === "monthly" &&
                                <p className='active_btn'> 25%</p>
                            }

                            {item.validity === "quarterly" &&

                                <p className='active_btn'> 50%</p>
                            }

                            {item.validity === "yearly" &&

                                <p className='active_btn'> 75%</p>
                            }
                            {/* ///////////// end % off /////////////////////////// */}

                            {SelectPackages?.chat_plan_id == item.id && <p className='active_btn'> Active</p>}


                            <h2 className="plan-title">{item.plan_name}</h2>

                            {/* ///////////// price /////////////////////////// */}
                            {item.validity === "monthly" &&

                                <p className="plan-price">
                                    <del>${(Number(Packages[0].amount) * 4).toFixed(2)}/ {item.validity}</del>
                                </p>
                            }

                            {item.validity === "quarterly" &&

                                <p className="plan-price">
                                    <del>${(Number(Packages[0].amount) * 12).toFixed(2)}/ {item.validity}</del>
                                </p>
                            }

                            {item.validity === "yearly" &&

                                <p className="plan-price">
                                    <del>${(Number(Packages[0].amount) * 52).toFixed(2)}/ {item.validity}</del>
                                </p>
                            }
                            {/* ///////////// end price /////////////////////////// */}


                            <p className="plan-price">
                                ${Number(item.amount)}<span>/ {item.validity}</span>
                            </p>



                            <ul>
                                <li>
                                    <img
                                        src="/images/inner-page-img/greater-than-symbol.png"
                                        alt="grater"
                                    />{" "}
                                    Starts chatting with Users
                                </li>

                                {/* //////////////// expire text ////////////////////// */}
                                {/* {item.validity === "weekly" && <li>
                                    <img
                                        src="/images/inner-page-img/greater-than-symbol.png"
                                        alt="grater"
                                    />{" "}
                                    Full week free
                                </li>}
                                {item.validity === "monthly" && <li>
                                    <img
                                        src="/images/inner-page-img/greater-than-symbol.png"
                                        alt="grater"
                                    />{" "}
                                    Full month free
                                </li>}
                                {item.validity === "quarterly" && <li>
                                    <img
                                        src="/images/inner-page-img/greater-than-symbol.png"
                                        alt="grater"
                                    />{" "}
                                    Three months free
                                </li>}
                                {item.validity === "yearly" && <li>
                                    <img
                                        src="/images/inner-page-img/greater-than-symbol.png"
                                        alt="grater"
                                    />{" "}
                                    Full year free
                                </li>} */}
                                {/* //////////////// end expire text ////////////////////// */}


                            </ul>

                            {SelectPackages === null && <>
                                <button type='button' className="btn" onClick={() => chooseAmount(item.amount, item.id)} >
                                    Join Now
                                </button>
                            </>}


                            {SelectPackages?.chat_plan_id == item.id && <>
                                <span className='expire_date'> Start On: {moment.unix(SelectPackages?.start_time).format("MM/DD/YYYY")}</span>
                            </>}

                            {SelectPackages?.chat_plan_id == item.id && <>
                                <span className='expire_date'> Expires On: {moment.unix(SelectPackages?.expire_time).format("MM/DD/YYYY")}</span>
                            </>}

                            {SelectPackages?.chat_plan_id == item.id && <>
                                <span className='expire_date'> Expires/ Countdown: {ExpireNow(SelectPackages?.expire_time)}</span>
                            </>}

                        </div>
                    </div>
                </>))}

            </div>
        </div>
    </>)
}

export default memo(ChatPackages)