import { NavLink } from "react-router-dom";
import { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PreLoader from "../PreLoader";
import ApiService from "../../services/ApiService";
import * as yup from "yup";


const FormSchama = yup.object({
    name: yup.string().required(),
    email: yup.string().required().email(),
    message: yup.string().required(),
});


function Index() {

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        reset,
        // setValue,
        // getValues,
        // trigger,
        formState: { errors },
        // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useForm({
        resolver: yupResolver(FormSchama),
        mode: 'all'
    });


    const FormSubmit = async (data) => {

        setLoader(true);

        // console.log(data)

        var new_data = {
            email: data.email,
            name:data.name,
            message:data.message
        };

        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().POST("/contact-us", new_data);

        if (responce.data.status==="1") {
            toast.success(responce.data.message);
           reset();
        } else {
            toast.success(responce.data.message);
        }

        setLoader(false);

    }


    return (
        <>

            {/* ==== Loader ==== */}
            <PreLoader Loader={Loader} />
            {/* ==== Loader ==== */}



            {/* ================== new ============================ */}
            <div className="page-section-pt text-white text-center contact-us-page" style={{ background: 'url(images/pattern/04.png) no-repeat 0 0', backgroundSize: 'cover' }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="row mb-4 mb-md-5">
                                <div className="col-md-12">
                                    <h2 className="title divider mb-3">Contact Us</h2>
                                    <p className="lead">Eum cu tantas legere complectitur, hinc utamur ea eam. Eum patrioque mnesarchum eu, diam erant convenire et vis. Et essent evertitur sea, vis cu ubique referrentur, sed eu dicant expetendis. Eum cu</p>
                                </div>
                            </div>
                            <div className="row mb-4 mb-md-5">

                                <div className="col-md-12 text-center" >
                                    <div className="address-block "> <i className="fa fa-desktop" aria-hidden="true" /> <a href="mailto:contact@realtimevillage.com">contact@realtimevillage.com</a> </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    {/* <h4 className="title divider-3">We Love Talking</h4> */}
                                </div>
                            </div>
                            <div className="row mb-0 mb-md-2 mb-lg-3 mb-xl-4">
                                <div className="col-md-12 mb-0 mb-lg-2">
                                    
                                    <form className="main-form" onSubmit={handleSubmit(FormSubmit)} >
                                        <div className="row contact-position">
                                            <div className="col-md-6 mb-3">
                                                <div className="input-group">
                                                    <input id="name" placeholder="Your name here" className="form-control" type="text"  {...register("name")} />
                                                    <p style={{ color: "red" }} className="form-field-error">
                                                        {errors.name?.message}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className="input-group ">
                                                    <input placeholder="Your mail here" className="form-control" type="email"  {...register("email")} />
                                                    <p style={{ color: "red" }} className="form-field-error">
                                                        {errors.email?.message}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <div className="input-group">
                                                    <textarea className="form-control input-message" placeholder="Your message here*" rows={7} defaultValue={""} {...register("message")} />
                                                    <p style={{ color: "red" }} className="form-field-error">
                                                        {errors.message?.message}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-0">
                                                
                                                <button type="submit" className="button btn-lg btn-theme full-rounded animated right-icn mb-0"><span>Submit Now<i className="glyph-icon flaticon-hearts" aria-hidden="true" /></span></button>
                                            </div>
                                        </div>
                                    </form>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default memo(Index)