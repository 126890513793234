import { NavLink } from "react-router-dom";
import PreLoader from "../../PreLoader";
import { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ApiService from "../../../services/ApiService";
import * as yup from "yup";


const FormSchama = yup.object({
    email: yup.string().required().email(),
});



function Index() {


    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const navigate = useNavigate();


    const {
        register,
        handleSubmit,
        reset,
        // setValue,
        // getValues,
        // trigger,
        formState: { errors },
        // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useForm({
        resolver: yupResolver(FormSchama),
        mode: 'all'
    });



    const FormSubmit = async (data) => {

        setLoader(true);

        // console.log(data)

        var new_data = {
            email: data.email,
        };

        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().POST("/forgot-password", new_data);

        if (responce.data.status) {
            toast.success("OTP send your email");
            navigate("/forgot-otp", { replace: true, state: { page_show_permission: true, email: data.email } });
        } else {
            toast.success("Something went wrong");
        }

        setLoader(false);

    }



    return (
        <>
            {/* ==== Loader ==== */}
            <PreLoader Loader={Loader} />
            {/* ==== Loader ==== */}


            <>
                {/*================================= inner-intro */}
                <main className="forgot-vh">
                <section
                    className=" inner-intro bg bg-fixed bg-overlay-black-60"
                    style={{ backgroundImage: "url(images/bg/main-breadcrumb.jpg)" }}
                >
                    <div className="container">
                        <div className="row intro-title text-center">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <h1 className="position-relative divider">
                                        forgot password{" "}
                                        <span className="sub-title">forgot password </span>
                                    </h1>
                                </div>
                            </div>
                            <div className="col-md-12 mt-7">
                                <ul className="page-breadcrumb">
                                    <li>
                                        <a href="index-default.html">
                                            <i className="fa fa-home" /> Home
                                        </a>{" "}
                                        <i className="fa fa-angle-double-right" />
                                    </li>
                                    <li>
                                        <span>forgot password </span>{" "}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/*================================= inner-intro */}
                {/*================================= forgot password */}
                <section
                    className="login-form login-img dark-bg page-section-ptb "
                    style={{
                        background: "url(images/pattern/04.png) no-repeat 0 0",
                        backgroundSize: "cover"
                    }}
                >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4">
                                <div className="login-1-form clearfix text-center">
                                    <h4 className="title divider-3 text-white">forgot password </h4>

                                    <form onSubmit={handleSubmit(FormSubmit)}>
                                        <div className="section-field mb-3 my-md-5">
                                            <div className="field-widget">
                                                {" "}
                                                <i className="glyph-icon flaticon-user" />
                                                <input
                                                    id="name"
                                                    className="web"
                                                    type="email"
                                                    placeholder="Email"
                                                    {...register("email")}
                                                />

                                                <p style={{ color: "red" }} className="form-field-error">
                                                    {errors.email?.message}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="clearfix" />
                                        <div className="section-field text-uppercase text-center mt-2">
                                            {" "}
                                            <button
                                                type="submit"
                                                className="button  btn-lg btn-theme full-rounded animated right-icn"
                                            >
                                                <span>
                                                    continue
                                                    <i
                                                        className="glyph-icon flaticon-hearts"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            </button>{" "}
                                        </div>
                                    </form>
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </main>
                {/*================================= forgot password */}
            </>


            {/* <Footer /> */}
        </>
    )
}

export default memo(Index)