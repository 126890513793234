import { Outlet } from "react-router-dom";
import Header from "./Header";
import PreLoader from "./PreLoader";
import Footer from "./Footer";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  ref,
  serverTimestamp,
  onChildAdded,
  onChildChanged,
  update,
} from "firebase/database";
import { firebase_db } from "../FirebaseSetup";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ApiService from "../services/ApiService";
import moment from "moment";
import { toast } from "react-toastify";
import { useStopwatch, useTimer } from "react-timer-hook";
import {
  useList,
  useObject,
  useObjectVal,
} from "react-firebase-hooks/database";
import { Rating } from "react-simple-star-rating";
import { Howl } from "howler";

import ringSoung from "../assect/sound/ring.mp3";
import dialSoung from "../assect/sound/dial.mp3";

function BasePage() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated, userData } = useSelector((state) => state.auth);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ShowReceiver, setShowReceiver] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ShowSender, setShowSender] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ShowRatingReceiver, setShowRatingReceiver] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ShowRatingSender, setShowRatingSender] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [RatingStar, setRatingStar] = useState(0);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [CallReqData, setCallReqData] = useState({});
  // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
  const navigate = useNavigate();

  const [RingSound] = useState(
    new Howl({
      src: [ringSoung],
      loop: true,
      volume: 1,
    })
  );

  const [DialSound] = useState(
    new Howl({
      src: [dialSoung],
      loop: true,
      volume: 0.5,
    })
  );

  const [snapshot, loading, error] = useList(
    ref(firebase_db, "user_video_audio_call_requests")
  );

  // const {
  //     totalSeconds,
  //     seconds,
  //     minutes,
  //     hours,
  //     days,
  //     isRunning,
  //     start,
  //     pause,
  //     reset,
  // } = useStopwatch({ autoStart: false });

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ onExpire: () => CallRequest("reject") });

  const CallRequest = async (status) => {
    setShowReceiver(false);
    setShowSender(false);

    if (status === "accept") {
      let new_data = {
        userid: CallReqData.receiver_id,
        requestid: CallReqData.sender_id,
        current_time: moment().unix(),
        notificationid: CallReqData.notificationid,
      };
      //  /////////////////// Api call ///////////////////////
      var responce = await new ApiService().POST(`/accept-request`, new_data);

      if (responce.data.status === "1") {
        /////// firebase update //////////////
        const updates = {};
        updates[`/user_video_audio_call_requests/${CallReqData.key}/status`] =
          status;
        updates[
          `/user_video_audio_call_requests/${CallReqData.key}/created_at`
        ] = serverTimestamp();
        update(ref(firebase_db), updates);
      } else {
        toast.warning(responce.data.message);
      }
    } else if (status === "reject") {
      let new_data = {
        userid: CallReqData.receiver_id,
        requestid: CallReqData.sender_id,
        current_time: moment().unix(),
        notificationid: CallReqData.notificationid,
      };

      //  /////////////////// Api call ///////////////////////
      var responce = await new ApiService().POST(`/declined-request`, new_data);
      console.log("declined-request ", responce.data);

      if (responce.data.status === "1") {
        /////// firebase update //////////////
        const updates = {};
        updates[`/user_video_audio_call_requests/${CallReqData.key}/status`] =
          status;
        updates[
          `/user_video_audio_call_requests/${CallReqData.key}/created_at`
        ] = serverTimestamp();
        update(ref(firebase_db), updates);
      } else {
        toast.warning(responce.data.message);
      }
    }

    RingSound.stop();
    DialSound.stop();
  };

  const CheckRating = async (snap_data, user_type) => {
    let new_data = {
      sender_id: userData.id,
      receiver_id:
        snap_data.sender_id === userData.id
          ? snap_data.receiver_id
          : snap_data.sender_id,
    };
    //  /////////////////// Api call ///////////////////////
    var responce = await new ApiService().POST(`/check-rating`, new_data);

    if (responce.data.status === "2") {
      if (user_type === "sender") {
        setShowRatingSender(true);
      } else if (user_type === "receiver") {
        setShowRatingReceiver(true);
      }

      setCallReqData(snap_data);
    } else {
      setShowRatingSender(false);
      setShowRatingReceiver(false);
      setCallReqData({});
    }
  };

  const SubmitRating = async () => {
    let new_data = {
      sender_id: userData.id,
      receiver_id:
        CallReqData.sender_id === userData.id
          ? CallReqData.receiver_id
          : CallReqData.sender_id,
      rating: RatingStar,
    };
    //  /////////////////// Api call ///////////////////////
    var responce = await new ApiService().POST(`/set-rating`, new_data);

    if (responce.data.status === "1") {
      /////// firebase update //////////////
      const updates = {};
      updates[
        `/user_video_audio_call_requests/${CallReqData.key}/rating_status`
      ] = "complete";
      updates[`/user_video_audio_call_requests/${CallReqData.key}/created_at`] =
        serverTimestamp();
      update(ref(firebase_db), updates);

      setShowRatingSender(false);
      setShowRatingReceiver(false);
      setCallReqData({});
      setRatingStar(0);
    }
  };

  const CancelRating = async () => {
    /////// firebase update //////////////
    const updates = {};
    updates[
      `/user_video_audio_call_requests/${CallReqData.key}/rating_status`
    ] = "reject";
    updates[`/user_video_audio_call_requests/${CallReqData.key}/created_at`] =
      serverTimestamp();
    update(ref(firebase_db), updates);

    setShowRatingSender(false);
    setShowRatingReceiver(false);
    setCallReqData({});
    setRatingStar(0);
  };

  const handleRating = (rate) => {
    setRatingStar(rate);
  };

  //// reject call after 1 minutes ////
  // const CallAutoReject = async () => {}

  // useEffect(() => {
  //     // if (snapshot !== undefined) {
  //     console.log("snapshot ", snapshot)
  //     // }
  // }, [snapshot])

  useEffect(() => {
    const AudioVideoCallRef = ref(
      firebase_db,
      `/user_video_audio_call_requests`
    );

    const unsubscribeAdded = onChildAdded(AudioVideoCallRef, (snapshot) => {
      const snap_data = snapshot.val();
      console.log("onChildAdded ", snap_data);

      //////////////////////// receiver ////////////////////////
      if (
        snap_data.receiver_id === userData.id &&
        snap_data.status === "pending"
      ) {
        setCallReqData(snap_data);
        setShowReceiver(true);
        RingSound.play();
      }
      //////////////////////// sender ////////////////////////
      else if (
        snap_data.sender_id === userData.id &&
        snap_data.status === "pending"
      ) {
        setCallReqData(snap_data);
        setShowSender(true);

        const expiryTimestamp = new Date();
        expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 60); // Set timer to 1 minute (60 seconds) from now
        restart(expiryTimestamp);

        DialSound.play();
      }
    });

    const unsubscribeChanged = onChildChanged(AudioVideoCallRef, (snapshot) => {
      const snap_data = snapshot.val();
      console.log("onChildChanged ", snap_data);

      //////////////////////// receiver ////////////////////////
      if (
        snap_data.receiver_id === userData.id &&
        snap_data.status === "accept"
      ) {
        setCallReqData({});
        setShowReceiver(false);

        if (snap_data.call_type === "audio") {
          navigate("/audio-call", {
            state: {
              page_access: true,
              room_id: snap_data.key,
              snap_data: snap_data,
            },
          });
        }

        if (snap_data.call_type === "video") {
          navigate("/video-call", {
            state: {
              page_access: true,
              room_id: snap_data.key,
              snap_data: snap_data,
            },
          });
        }

        RingSound.stop();
        DialSound.stop();
      } else if (
        snap_data.receiver_id === userData.id &&
        snap_data.status === "reject"
      ) {
        setCallReqData({});
        setShowReceiver(false);

        RingSound.stop();
        DialSound.stop();
      } else if (
        snap_data.receiver_id === userData.id &&
        snap_data.status === "complete" &&
        snap_data.rating_status === "pending"
      ) {
        CheckRating(snap_data, "receiver");
      }
      //////////////////////// sender ////////////////////////
      else if (
        snap_data.sender_id === userData.id &&
        snap_data.status === "accept"
      ) {
        setCallReqData({});
        setShowSender(false);

        if (snap_data.call_type === "audio") {
          navigate("/audio-call", {
            state: {
              page_access: true,
              room_id: snap_data.key,
              snap_data: snap_data,
            },
          });
        }

        if (snap_data.call_type === "video") {
          navigate("/video-call", {
            state: {
              page_access: true,
              room_id: snap_data.key,
              snap_data: snap_data,
            },
          });
        }

        RingSound.stop();
        DialSound.stop();
      } else if (
        snap_data.sender_id === userData.id &&
        snap_data.status === "reject"
      ) {
        setCallReqData({});
        setShowSender(false);

        RingSound.stop();
        DialSound.stop();
      } else if (
        snap_data.sender_id === userData.id &&
        snap_data.status === "complete" &&
        snap_data.rating_status === "pending"
      ) {
        CheckRating(snap_data, "sender");
      }
    });

    return () => {
      unsubscribeAdded();
      unsubscribeChanged();
    };
  }, [snapshot]);

  // useEffect(() => {
  //     const AudioVideoCallRef = ref(firebase_db, `/user_video_audio_call_requests`);

  //     const unsubscribeChanged = onChildChanged(AudioVideoCallRef, (snapshot) => {
  //         const snap_data = snapshot.val();
  //         console.log("onChildChanged ", snap_data);

  //         //////////////////////// receiver ////////////////////////
  //         if (snap_data.receiver_id === userData.id && snap_data.status === "accept") {
  //             setCallReqData({});
  //             setShowReceiver(false);

  //             if (snap_data.call_type === "audio") {
  //                 navigate("/audio-call", {
  //                     state: {
  //                         page_access: true,
  //                         room_id: snap_data.key,
  //                         snap_data: snap_data
  //                     }
  //                 })
  //             }

  //             if (snap_data.call_type === "video") {
  //                 navigate("/video-call", {
  //                     state: {
  //                         page_access: true,
  //                         room_id: snap_data.key,
  //                         snap_data: snap_data
  //                     }
  //                 })
  //             }
  //         }
  //         else if (snap_data.receiver_id === userData.id && snap_data.status === "reject") {
  //             setCallReqData({});
  //             setShowReceiver(false);
  //         }
  //         //////////////////////// sender ////////////////////////
  //         else if (snap_data.sender_id === userData.id && snap_data.status === "accept") {
  //             setCallReqData({});
  //             setShowSender(false);

  //             if (snap_data.call_type === "audio") {
  //                 navigate("/audio-call", {
  //                     state: {
  //                         page_access: true,
  //                         room_id: snap_data.key,
  //                         snap_data: snap_data
  //                     }
  //                 })
  //             }

  //             if (snap_data.call_type === "video") {
  //                 navigate("/video-call", {
  //                     state: {
  //                         page_access: true,
  //                         room_id: snap_data.key,
  //                         snap_data: snap_data
  //                     }
  //                 })
  //             }
  //         }
  //         else if (snap_data.sender_id === userData.id && snap_data.status === "reject") {
  //             setCallReqData({});
  //             setShowSender(false);
  //         }

  //     });

  // }, []);

  // Stop the stopwatch after 5 minutes
  // useEffect(() => {
  //     if (totalSeconds >= (1 * 60)) {
  //         pause();
  //         // CallAutoReject();
  //         CallRequest("reject");
  //     }
  // }, [totalSeconds]);

  return (
    <>
      <PreLoader />
      <Header />
      <Outlet />
      <Footer />

      {/* ///////////// Reciver Call Modal ///////////////// */}
      <Modal
        show={ShowReceiver}
        className="callRequestModal"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Calling</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{CallReqData?.sender_details?.name}</p>
          <img
            src={CallReqData?.sender_details?.profile_pic_pro_resize_200_url}
            width={"60px"}
            height={"60px"}
            alt=""
          />
          <div className="backdrop">
            <div className="ba-drp-callicon">
              {CallReqData?.call_type === "video" && <span>Video Call charges ${CallReqData?.ratePerMinute?.toFixed(2)}/min </span>}
              {CallReqData?.call_type === "audio" && <span>Audio Call charges ${CallReqData?.ratePerMinute?.toFixed(2)}/min </span>}
            </div>
          </div>
          <div className="backdrop">
            <div className="ba-drp-callicon">
              {CallReqData?.call_type === "video" && <i className="fa-solid fa-video"></i>}
              {CallReqData?.call_type === "audio" && <i className="fa-solid fa-phone"></i>}
            </div>
            <span> Incoming Call</span>
            <div className="loader">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="call-rec-popup"
            onClick={() => CallRequest("accept")}
          >
            <i className="fa-solid fa-phone"></i>
          </button>
          <button
            className="call-end-popup"
            onClick={() => CallRequest("reject")}
          >
            <i className="fa-solid fa-phone"></i>
          </button>
        </Modal.Footer>
      </Modal>

      {/* ///////////// Sender Call Modal ///////////////// */}
      <Modal
        show={ShowSender}
        className="callRequestModal"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Calling</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={CallReqData?.receiver_details?.profile_pic_pro_resize_200_url}
            width={"60px"}
            height={"60px"}
            alt=""
          />
          <p>{CallReqData?.receiver_details?.name}</p>
          <div className="backdrop">
            <div>
              {CallReqData?.call_type === "video" && <i className="fa-solid fa-video"></i>}
              {CallReqData?.call_type === "audio" && <i className="fa-solid fa-phone"></i>}
            </div>
            <span> Outgoing Call</span>
            <div className="loader">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="call-end-popup"
            onClick={() => CallRequest("reject")}
          >
            <i className="fa-solid fa-phone"></i>
          </button>
        </Modal.Footer>
      </Modal>

      {/* ///////////// Reciver Show Rating Modal ///////////////// */}
      <Modal
        show={ShowRatingReceiver}
        className="callRequestModal"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Call Rating</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{CallReqData?.sender_details?.name}</p>
          <img
            src={CallReqData?.sender_details?.profile_pic_pro_resize_200_url}
            width={"60px"}
            height={"60px"}
            alt=""
          />
          <Rating
            className="rating_star"
            onClick={handleRating}
            initialValue={RatingStar}
            iconsCount={5}
            allowFraction={true}
            transition={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={SubmitRating}>
            submit
          </button>
          <button className="btn btn-primary" onClick={CancelRating}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* ///////////// Sender Show Rating Modal ///////////////// */}
      <Modal
        show={ShowRatingSender}
        className="callRequestModal"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Call Rating</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{CallReqData?.receiver_details?.name}</p>
          <img
            src={CallReqData?.receiver_details?.profile_pic_pro_resize_200_url}
            width={"60px"}
            height={"60px"}
            alt=""
          />
          <Rating
            className="rating_star"
            onClick={handleRating}
            initialValue={RatingStar}
            iconsCount={5}
            allowFraction={true}
            transition={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={SubmitRating}>
            submit
          </button>
          <button className="btn btn-primary" onClick={CancelRating}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default memo(BasePage);
