import React, { memo, useEffect, useState } from 'react'
import ApiService from '../../services/ApiService';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

function Funds() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { userData } = useSelector(state => state.auth);

    const [Funds, setFunds] = useState([]);

    const navigate = useNavigate();

    const getRechargeAmounts = async () => {
        /////////////////// Api call ///////////////////////
        var response = await new ApiService().GET(`/get-recharge-amount`);
        console.log("get-recharge-amount ", response.data);

        if (response.data.status === '1') {
            setFunds(response.data.data);
        }
    };

    const chooseAmount = (amount) => {
        navigate("/payment", {
            state: {
                payment_type: "recharge",
                amount
            }
        })
    };

    useEffect(() => {
        getRechargeAmounts();
    }, [])


    return (<>
        <div className="add-fund">
            <h2>Your Wallet Amount</h2>
            <ul className="a-f-m">
                <li>${Number(userData.wallet_amount).toFixed(2)}</li>
            </ul>

            <br />
            <h2>Select Amount</h2>
            <p>Please select the amount you want to add to your wallet</p>
            <ul>
                {/* <li>$10</li>
                <li>$10</li>
                <li>$30</li>
                <li>$40</li>
                <li>$50</li> */}
                {Funds.map((item, index) => (<>
                    <li key={index} onClick={() => chooseAmount(item.amount)}>${item.amount}</li>
                </>))}
            </ul>
        </div>
    </>)
}

export default memo(Funds)