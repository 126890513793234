import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import AgoraRTC from "agora-rtc-sdk-ng";


////////////// Redux //////////////
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
////////////// Redux //////////////

// AgoraRTC.setLogLevel(/* DEBUG */ 0);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);


