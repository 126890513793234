import React, { useCallback, useEffect, useState } from "react";
import AgoraRTC, {
  useRTCClient,
  useIsConnected,
  useClientEvent,
  LocalUser,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  LocalAudioTrack,
  useLocalAudioTrack,
  RemoteUser,
  useRemoteUsers,
  RemoteAudioTrack,
  useJoin,
  usePublish,
  LocalVideoTrack,
  AgoraRTCProvider,
} from "agora-rtc-react";
// import AgoraRTC from "agora-rtc-sdk-ng";

import { useStopwatch } from "react-timer-hook";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../../redux/auth";
import {
  ref,
  child,
  get,
  serverTimestamp,
  onChildChanged,
  update,
} from "firebase/database";
import { firebase_db } from "../../../FirebaseSetup";
import ApiService from "../../../services/ApiService";
import Swal from "sweetalert2";

const AgoraManager = ({ config, location }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { userData } = useSelector((state) => state.auth);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  const agoraEngine = useRTCClient();

  const navigate = useNavigate();
  const [MicMuted, setMicMuted] = useState(false);
  const [Volumn, setVolumn] = useState(10);

  const { localMicrophoneTrack } = useLocalMicrophoneTrack();
  const isConnected = useIsConnected();

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });

  useJoin({
    appid: config.appId,
    channel: config.channel,
    token: config.token,
  });

  //// publish microphone or camera track (give permission) ////
  usePublish([localMicrophoneTrack]);

  const remoteUsers = useRemoteUsers();

  // Event listener for user left
  useClientEvent(agoraEngine, "user-left", (user) => {
    console.log("The user", user.uid, "has left the channel");
    // alert("user_left")
    CallEnd();
  });

  // Event listener for user published
  useClientEvent(agoraEngine, "user-published", (user, mediaType) => {
    console.log("The user", user.uid, "has published media in the channel");
  });

  const toggleMic = () => {
    setMicMuted((prev) => !prev);
  };

  const toggleSpeakerphone = useCallback(() => {
    if (Volumn === 10) {
      setVolumn(100);
    } else {
      setVolumn(10);
    }
  }, [Volumn]);

  const CallEnd = async () => {
    let new_data = {
      userid: location.state.snap_data.sender_id,
      call_duration: totalSeconds, // given value in second
      callerid: location.state.snap_data.receiver_id,
      genKey: location.state.room_id,
      call_type: "audiocall", // video or audio
    };

    ///////////////////// Api call ///////////////////////
    var responce = await new ApiService().POST(`/balance-deduct`, new_data);

    // alert("Video Call with  has ended. $ " + responce.data.balance_deduct + " has therefore been deducted from your Wallet.");

    ///// firebase update //////////////
    const updates = {};
    updates[
      `/user_video_audio_call_requests/${location.state.room_id}/status`
    ] = "complete";
    updates[
      `/user_video_audio_call_requests/${location.state.room_id}/created_at`
    ] = serverTimestamp();
    update(ref(firebase_db), updates);

    /////////////////// Api call ///////////////////////
    var responce_2 = await new ApiService().POST("/user-info", {
      userid: userData.id,
    });

    var resData = responce_2.data.user_details[0];
    // console.log("getUserDetails ", resData);
    dispatch(authActions.UpdateUserData(resData));

    Swal.fire(responce.data.message);

    console.log("call end");

    localMicrophoneTrack?.close();

    navigate("/find-match");
    // window.location.replace("/find-match");
  };

  // Stop the stopwatch after 5 minutes
  useEffect(() => {
    if (totalSeconds >= Number(location.state.snap_data.totalSeconds)) {
      pause();
      CallEnd();
    }
    // if (totalSeconds >= (2 * 60)) {
    //     pause();
    //     CallEnd();
    // }
  }, [totalSeconds, pause]);

  return (
    <div className="audio_call">
      {/* Video Grid for Remote Users */}
      {remoteUsers.map((user) => (
        <div className="remote_user" key={user.uid}>
          <RemoteUser
            user={user}
            audioTrack={user.audioTrack}
            playAudio={true}
            playVideo={false}
            volume={Volumn}
            className="remote_player"
          />
        </div>
      ))}

      {/* <div className="remote_user">
                {audioTracks.map((track) => (
                    <RemoteAudioTrack
                        key={track.getUserId()}
                        play={true}
                        track={track} />
                ))}
            </div> */}

      {/* <div className="remote_user">
                {remoteUsers.map((user) => (
                    <RemoteAudioTrack
                        user={user}
                        key={user.uid}
                        play={true}
                        track={user.audioTrack} />
                ))}
            </div> */}

      {/* Local Video */}
      {isConnected && (
        <>
          <div className="local_user">
            <LocalUser
              audioTrack={localMicrophoneTrack}
              cameraOn={false}
              micOn={!MicMuted}
              playVideo={false}
              playAudio={false}
              className="local_player"
            />
          </div>
        </>
      )}

      {/* {isConnected && <>
                <div className="local_user">
                    <LocalAudioTrack
                        track={localMicrophoneTrack}
                        play={false}
                        micOn={!MicMuted}
                    />
                </div>
            </>} */}

      <div id="members">
        {userData.id === location.state.snap_data.sender_id && (
          <>
            <p>{location.state.snap_data.receiver_details.name}</p>
            <img
              src={
                location.state.snap_data.receiver_details
                  .profile_pic_pro_resize_200_url
              }
              alt=""
              width={"60px"}
              height={"60px"}
            />
          </>
        )}

        {userData.id === location.state.snap_data.receiver_id && (
          <>
            <p>{location.state.snap_data.sender_details.name}</p>
            <img
              src={
                location.state.snap_data.sender_details
                  .profile_pic_pro_resize_200_url
              }
              alt=""
              width={"60px"}
              height={"60px"}
            />
          </>
        )}

        <div
          className="audio_call_timer"
          style={{ fontSize: "30px", fontWeight: "800" }}
        >
          <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
        </div>
      </div>

      <div className="au-wrap">
        {/* Mute mic */}
        {/* <button onClick={toggleMic}>
                    {MicMuted ? 'Unmute Mic' : 'Mute Mic'}
                </button> */}

        <button onClick={toggleMic}>
          {MicMuted ? (
            <>
              <i className="fa-solid fa-microphone"></i>
              <span>Mute</span>
            </>
          ) : (
            <>
              <i class="fa-solid fa-microphone-slash"></i>
              <span>UnMute</span>
            </>
          )}
        </button>

        {/* <button onClick={toggleSpeakerphone}>
                <i class="fa-solid fa-headphones"></i>
                    {Volumn===100 ? "Speaker Off" : "Speaker On"}
                  
                </button> */}

        <button onClick={toggleSpeakerphone}>
          {Volumn === 100 ? (
            <>
              <i class="fa-solid fa-volume-low"></i>
              <span>Phone</span>
            </>
          ) : (
            <>
              <i class="fa-solid fa-volume-high"></i>
              <span>Speaker</span>
            </>
          )}
        </button>

        {/* End Call Button */}
        <button className="call-end" onClick={CallEnd}>
          <i class="fa-solid fa-phone-slash"></i>
          <span>Call End</span>
        </button>
      </div>
      {/* Additional UI Components or Children */}
    </div>
  );
};

// App Component
const Index = () => {
  const location = useLocation();

  const config = {
    // Your Agora App Config
    appId: "0feea1b1632c49cebb1b8aa53b7fdc00",
    channel: location.state.room_id,
    token: null,
  };

  const [client] = useState(() =>
    AgoraRTC.createClient({ mode: "rtc", codec: "vp8" })
  );

  return (
    <AgoraRTCProvider client={client}>
      <AgoraManager config={config} location={location} />
    </AgoraRTCProvider>
  );
};

export default Index;
