import React, { memo, useEffect, useState } from 'react'
import ApiService from '../services/ApiService';
import parse from 'html-react-parser';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ShowMoreText from "react-show-more-text";

function Blogs() {


    const [BlogData, setBlogData] = useState([]);

    const getAllBlogs = async () => {
        const responce = await new ApiService().GET("/get-all-blogs");

        if (responce.data.status === '1') {
            setBlogData(responce.data.data);
        }
    };

    const LongTextToShortText = (text) => {
        const truncatedText = text.length > 200 ? text.slice(0, 200) + '...' : text;
        return truncatedText;
    }


    useEffect(() => {
        getAllBlogs();
    }, [])

    return (<>
        <section className="page-section-ptb grey-bg">
            <div className="container">
                <div className="row justify-content-center mb-5 sm-mb-3">
                    <div className="col-md-8 text-center">
                        <h2 className="title divider-2 mb-3">Our Recent Blogs</h2>
                        <p className="lead mb-0">
                            Nulla quis lorem ut libero malesuada feugiat. Curabitur non nulla
                            sit amet nisl tempus convallis quis ac lectus. Quisque velit nisi,
                            pretium ut lacinia in, elementum id enim.
                        </p>
                    </div>
                </div>
                <div className="row post-article">

                    {BlogData.map((item, index) => (<>
                        <div className="col-md-4" key={`blog_${index}`}>
                            <div className="post post-artical top-pos">
                                <div className="post-image clearfix">
                                    <img className="img-fluid" src={item.image_url} alt="" />
                                </div>
                                <div className="post-details">
                                    <div className="date-title-wrap">
                                    <div className="post-date">
                                        {moment(item.posted_on).format('DD')}<span className="text-black">{moment(item.posted_on).format('MMM')}</span>
                                    </div>
                                    {/* <div className="post-meta">
                                        {" "}
                                        <a href="#">
                                            <i className="fa fa-user" /> Admin
                                        </a>{" "}
                                        <a href="#">
                                            <i aria-hidden="true" className="fa fa-heart-o" />
                                            98 Like
                                        </a>{" "}
                                        <a href="#">
                                            <i className="fa fa-comments-o last-child" />
                                            Comments
                                        </a>{" "}
                                    </div> */}
                                    <div className="post-title">
                                        <h5 className="title text-uppercase">
                                            <Link to="#">
                                                {parse(item.title)}
                                            </Link>
                                        </h5>
                                    </div>
                                    </div>
                                    <div className="post-content">
                                        {/* {parse(LongTextToShortText(item.details))} */}
                                        <ShowMoreText
                                            lines={3}
                                            more='Show more'
                                            less='Show less'
                                            anchorClass='show-more'
                                            expanded={false}
                                            width={280}
                                        >
                                            {parse(item.details)}
                                        </ShowMoreText>
                                    </div>
                                    {/* <Link className="button" to="#">
                                        read more..
                                    </Link>{" "} */}
                                </div>
                            </div>
                        </div>
                    </>))}

                    {/* <div className="col-md-4">
                        <div className="post post-artical top-pos">
                            <div className="post-image clearfix">
                                <img className="img-fluid" src="/images/bg/new_bg2.jpg" alt="" />
                            </div>
                            <div className="post-details">
                                <div className="date-title-wrap">
                                <div className="post-date">
                                    27<span className="text-black">MAR</span>
                                </div>
                            
                                <div className="post-title">
                                    <h5 className="title text-uppercase">
                                        <a href="blog-singal-fullwidth.html">
                                            Intentions That Energize You
                                        </a>
                                    </h5>
                                </div>
                                </div>
                                <div className="post-meta">
                                    {" "}
                                    <a href="#">
                                        <i className="fa fa-user" /> Admin
                                    </a>{" "}
                                    <a href="#">
                                        <i aria-hidden="true" className="fa fa-heart-o" />
                                        98 Like
                                    </a>{" "}
                                    <a href="#">
                                        <i className="fa fa-comments-o last-child" />
                                        Comments
                                    </a>{" "}
                                </div>
                                <div className="post-content">
                                    <p>
                                        Cras ultricies ligula sed magna dictum porta. Quisque velit
                                        nisi, pretium ut lacinia in, elementum id enim. Pellentesque
                                        in ipsum id orci porta dapibus. Curabitur aliquet quam..
                                    </p>
                                </div>
                                <a className="button" href="blog-singal-fullwidth.html">
                                    read more..
                                </a>{" "}
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
        </section>
    </>)
}

export default memo(Blogs)