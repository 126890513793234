import React from 'react';

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Checkout from './Checkout';
// const stripePromise = loadStripe("pk_live_51Lei4iEf44FLWrDkQt4ddKrcl09PPIp6zMmUXivO04Z9rVqOFh9Nkai4jPawkuN2yD04pteTrxqL3mBlDOfiLXpS00Sszn2b5c"); 

function Index({ StripePublishKey, ClientSecret, payment_type, amount, chat_plan_id }) {
    const stripePromise = loadStripe(StripePublishKey);

    const options = {
        theme: 'stripe',
        loader: 'always',
        // passing the client secret obtained from the server
        clientSecret: ClientSecret,
    };


    return (<>
        {/* PaymentElement */}
        <Elements stripe={stripePromise} options={options}>
            <Checkout payment_type={payment_type} amount={amount} chat_plan_id={chat_plan_id}  />
        </Elements>

    </>)
}

export default Index