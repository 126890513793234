import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../../services/ApiService';
import { useDispatch, useSelector } from "react-redux";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { authActions } from '../../../redux/auth';
import PreLoader from '../../PreLoader';


function Checkout({ payment_type, amount, chat_plan_id }) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { userData } = useSelector(state => state.auth);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const navigate = useNavigate();


    const getUserDetails = async () => {

        setLoader(true);

        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().POST("/user-info", { userid: userData.id });

        var resData = responce.data.user_details[0];
        // console.log("getUserDetails ", resData);
        dispatch(authActions.UpdateUserData(resData));

        setLoader(false);

    }


    // CardElement
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {


            // Trigger form validation and wallet collection
            const { error: submitError } = await elements.submit();
            if (submitError) {
                console.log("stripe_error ", submitError.message)
                return;
            }

            setLoader(true);


            const confirmPayment = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${process.env.REACT_APP_WEB_URL}/payment-status`,
                },
                redirect: 'if_required'
            });



            console.log(confirmPayment);

            const { paymentIntent, error } = confirmPayment;



            if (error) {
                toast.error(error.message);
                setLoader(false);
            }
            else if (paymentIntent.status === "succeeded") {
                // alert(`Payment successful!`);

                const payload_2 = {
                    userId: userData.id,
                    amount,
                    payment_details: paymentIntent,
                    payFor: chat_plan_id ? 'chat_subscription' : 'wallet',
                    chat_plan_id: chat_plan_id ? chat_plan_id : "",
                    pay_platform: "web",
                }


                /////////////////// Api call ///////////////////////
                const response_2 = await new ApiService().POST(`/stripe-payment-save`, payload_2);
                console.log("stripe-payment-save ", response_2.data);

                if (response_2.data.status === '1') {

                    await getUserDetails();

                    setLoader(false);
                    navigate("/payment-status", {
                        state: {
                            status: "succeeded"
                        }
                    });

                } else {
                    setLoader(false);
                    navigate("/payment-status", {
                        state: {
                            status: "failed"
                        }
                    });
                }

            }
            else {
                // alert(`Payment failed!`);
                setLoader(false);
                navigate("/payment-status", {
                    state: {
                        status: "failed"
                    }
                });
            }

        } catch (err) {
            console.error(err);
        }
    };


    return (<>

        {/* ==== Loader ==== */}
        <PreLoader Loader={Loader} />
        {/* ==== Loader ==== */}

        <div className="stripeCard-center">
            <div className='stripeCard'>

                <form onSubmit={handleSubmit}>

                    <PaymentElement />

                    <button type="submit" disabled={Loader} >
                        Pay
                    </button>
                </form>
            </div>
        </div>
    </>)
}

export default Checkout