import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


function Footer() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useSelector((state) => state.auth);

  return (
    <>
      {/*================================= footer */}
      <footer className="text-white text-center">
        <div className="footer-widget">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                {!isAuthenticated && <>  <div className="footer-logo mb-2">
                  {" "}
                  <img
                    className="img-center"
                    src="/images/home-logo.png"
                    alt=""
                  />{" "}
                </div>
                  <div className="footer-list">
                    <ul>
                      <li>
                        <Link to="/product-services">PRODUCTS & SERVICES</Link>
                      </li>
                      <li>
                        <Link to="/return-refund-policy">RETURN & REFUND POLICY</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">PRIVACY POLICY</Link>
                      </li>
                      <li>
                        <Link to="/terms-condition">TERMS & CONDITIONS</Link>
                      </li>
                      {/* <li><a href="#">FAQ</a></li> */}
                      <li>
                        <Link to="/disclaimer">DISCLAIMER</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="social-icons color-hover">
                    <ul>
                      <li className="social-facebook"><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                      <li className="social-dribbble"><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                      <li className="social-twitter"><a href="#"><i class="fa-brands fa-x-twitter"></i></a></li>
                      <li className="social-gplus"><a href="#"><i class="fa-brands fa-youtube"></i></a></li>
                      <li className="social-youtube"><a href="#"><i class="fa-brands fa-whatsapp"></i></a></li>
                      <li className="social-youtube"><a href="#"><i class="fa-brands fa-tiktok"></i></a></li>
                      <li className="social-youtube"><a href="#"><i class="fa-brands fa-linkedin-in"></i></a></li>
                    </ul>
                  </div> </>}
                <p className="text-white">
                  Copyright © 2024 Real Time Village{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/*================================= footer */}
      {/*================================= Back to Top */}
      <div id="back-to-top">
        <a className="top arrow" href="#top">
          <i className="fa fa-level-up" />
        </a>
      </div>
    </>
  )
}

export default Footer