import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  userId: 0,
  userData:{},
  token:"",
  identificationStatus:false,
  preferenceStatus:false,
};

const authSlice = createSlice ({
  name: 'authentication',
  initialState: initialState,
  reducers: {
    Login (state,action) {
      state.isAuthenticated = true;
      state.userId=action.payload.user_details[0].id;
      state.userData=action.payload.user_details[0];
      // state.token=action.payload.token;
      // localStorage.setItem("token",action.payload.token);
      console.log("user_data print: ",action.payload.user_details[0]);
    },

    UpdateUserData (state,action) {
      state.userData=action.payload;
      console.log("updated user_data print: ",action.payload);
    },

    // UpdateUserImageData (state,action) {
    //   var old_data=action.payload.user_details[0];
    //   state.userData=action.payload.user_details[0];
    //   console.log("updated user_image_data print: ",action.payload.user_details[0]);
    // },

    ChangeIdentificationStatus (state,action){
      state.identificationStatus=action.payload;
    },

    ChangePreferenceStatus (state,action){
      state.preferenceStatus=action.payload;
    },

    Logout (state) {
      state.isAuthenticated = false;
      state.userId=0;
      state.userData={};
      state.identificationStatus=false;
      state.preferenceStatus=false;
      state.token="";
      localStorage.clear ();
      sessionStorage.clear();
    },

  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;