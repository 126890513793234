import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
// export const UserRequireAuth = ({children}) => {
//   const {isAuthenticated, identificationStatus,preferenceStatus} = useSelector (state => state.auth);

//   const location = useLocation ();

//   if (!isAuthenticated) {
//     return <Navigate to="/" state={{path: location.pathname}} />;
//   } else if (isAuthenticated && isAdmin) {
//     return <Navigate to="/admin" state={{path: location.pathname}} />;
//   } else {
//     return children;
//   }
// };


// eslint-disable-next-line react/prop-types
export const RequireAuth = ({ children }) => {
    const { isAuthenticated, identificationStatus, preferenceStatus } = useSelector(state => state.auth);

    const location = useLocation();
    

    if (isAuthenticated) {

        if (!identificationStatus) {
            return <Navigate to="/identification" state={{ path: location.pathname, page_show_permission: true }} />

        } else if (!preferenceStatus) {
            return <Navigate to="/preferences" state={{ path: location.pathname, page_show_permission: true }} />
        }

        return children;

    } else {
        localStorage.clear();
        sessionStorage.clear();

        return <Navigate to="/" state={{ path: location.pathname }} />;
    }


};

// eslint-disable-next-line react/prop-types
export const WithOutAuth = ({ children }) => {
    const { isAuthenticated, identificationStatus, preferenceStatus } = useSelector(state => state.auth);

    const location = useLocation();

    if (isAuthenticated) {

        if (!identificationStatus) {
            return <Navigate to="/identification" state={{ path: location.pathname, page_show_permission: true }} />

        } else if (!preferenceStatus) {
            return <Navigate to="/preferences" state={{ path: location.pathname, page_show_permission: true }} />
        }
        
        if (location.pathname === "/find-match") {
            return children
        } else {
            return <Navigate to="/find-match" state={{ path: location.pathname }} />;
        }
    }

    localStorage.clear();
    sessionStorage.clear();

    return children;
};


// eslint-disable-next-line react/prop-types
export const CallStateCheck = ({ children }) => {
    const { isAuthenticated, identificationStatus, preferenceStatus } = useSelector(state => state.auth);

    const location = useLocation();

    if (isAuthenticated) {

        if (location.state !== null) {
            return children;

        } else {
            return <Navigate to="/find-match" state={{ path: location.pathname }} />;
        }

    } else {
        return <Navigate to="/" state={{ path: location.pathname }} />;
    }
};







