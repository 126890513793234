import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PreLoader from "../PreLoader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UpdateUserProfileSetupSchama, UpdateUserProfileImageSetupSchama } from "../../Schemas";
import { Country } from "country-state-city";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import ApiService from '../../services/ApiService';
import { authActions } from '../../redux/auth';
import { toast } from "react-toastify";
import Select from 'react-select';

const heightData = [
    { label: 'Under 5’0"', value: '5.0' },
    { label: '5’1"', value: '5.1' },
    { label: '5’2"', value: '5.2' },
    { label: '5’3"', value: '5.3' },
    { label: '5’4"', value: '5.4' },
    { label: '5’5"', value: '5.5' },
    { label: '5’6"', value: '5.6' },
    { label: '5’7"', value: '5.7' },
    { label: '5’8"', value: '5.8' },
    { label: '5’9"', value: '5.9' },
    { label: '6’0"', value: '6.0' },
    { label: '6’1"', value: '6.1' },
    { label: '6’2"', value: '6.2' },
    { label: '6’3"', value: '6.3' },
    { label: '6’4"', value: '6.4' },
    { label: '6’5"', value: '6.5' },
    { label: '6’6"', value: '6.6' },
    { label: '6’7"', value: '6.7' },
    { label: '6’8"', value: '6.8' },
    { label: '6’9"', value: '6.9' },
    { label: '7’0"', value: '7.0' },
    { label: '7’1"', value: '7.1' },
    { label: '7’2"', value: '7.2' },
    { label: '7’3"', value: '7.3' },
    { label: '7’4"', value: '7.4' },
    { label: '7’5"', value: '7.5' },
]

function EditProfile() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { userData } = useSelector(state => state.auth);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-undef
    const [PreviewImage, setPreviewImage] = useState("/images/demo-one.png");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [countries, setCountries] = useState([]);
    const [SelectedCountry, setSelectedCountry] = useState(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);


    ///////////  Phone ///////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [PhoneValue, setPhoneValue] = useState("");

    // eslint-disable-next-line no-unused-vars, react-hooks/rules-of-hooks
    const [PhoneValueError, setPhoneValueError] = useState("");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [PhoneSelectedCountry, setPhoneSelectedCountry] = useState("");
    ///////////  End Phone ///////////

    // /////////////// Form value ////////////////////
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        // getValues,
        // trigger,
        // control,
        formState: { errors },
        // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useForm({
        resolver: yupResolver(UpdateUserProfileSetupSchama),
        mode: 'all'
    });

    // /////////////// Image Form value ////////////////////
    const {
        register: register_2,
        handleSubmit: handleSubmit_2,
        reset: reset_2,
        setValue: setValue_2,
        // getValues: getValues_2,
        // trigger: trigger_2,
        // control: control_2,
        formState: { errors: errors_2 },
        // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useForm({
        resolver: yupResolver(UpdateUserProfileImageSetupSchama),
        mode: 'all'
    });


    const ChooseCountry = (data) => {
        setSelectedCountry(data);

        // console.log(data)

        setValue("country", data.value)

    }

    // const uploadPreviewImage = (e) => {

    //     const file = e.target.files[0];

    //     if (file) {
    //         // Display the preview of the image
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setPreviewImage(reader.result);
    //             setValue_2("profile_pic", e.target.files[0]);
    //         };
    //         reader.readAsDataURL(file);
    //     } else {
    //         setPreviewImage("/images/demo-one.png");
    //         setValue_2("profile_pic", null);
    //     }

    // }

    const getUserDetails = async () => {

        setLoader(true);

        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().POST("/user-info", { userid: userData.id });

        var resData = responce.data.user_details[0];
        // console.log("getUserDetails ", resData);
        dispatch(authActions.UpdateUserData(resData));

        setLoader(false);

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fromSetUserDataFromRedux = () => {

        setValue("name", userData.name);
        setValue("email", userData.email);
        setValue("age", userData.age);
        setValue("country", userData.country);
        setValue("city", userData.city);
        setValue("zipcode", userData.zipcode);
        setValue("gender", userData.gender);
        setValue("occupation", userData.occupation);
        setValue("marital", userData.marital);
        setValue("education", userData.education);
        setValue("height", userData.height);
        setValue("bodyType", userData.bodyType);
        // setPhoneValue(userData.telephone);

        if (userData.country) {
            const countryByIso2 = Country.getCountryByCode(userData.country);
            // console.log("countryByIso2",countryByIso2);
            setSelectedCountry({ value: countryByIso2.isoCode, label: countryByIso2.name });
        }


        // console.log(userData.country)
    }

    const submit = async (data) => {

        //////////  phone number validation /////////////
        // console.log(PhoneValue);
        // console.log(PhoneSelectedCountry);
        // console.log(isValidPhoneNumber(PhoneValue));

        // if (PhoneValue !== "" && PhoneValue !== undefined) {
        //     if (isValidPhoneNumber(PhoneValue) === false) {
        //         setPhoneValueError("Enter valid Phone number");
        //         return;
        //     }
        // }
        // setPhoneValueError("");
        //////////  phone number validation /////////////

        // data.telephone = PhoneValue ? PhoneValue : "";
        // data.telephone_code = PhoneSelectedCountry ? PhoneSelectedCountry : "";
        data.userid = userData.id;

        // console.log(data)

        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().POST("/profile-update", data);

        // console.log(responce.data)

        if (responce.data.status == '1') {
            toast.success("Profile update successfully");
            await getUserDetails();
        }

    }

    // const imgSubmit = async (data) => {

    //     // console.log(data)

    //     var formData = new FormData();
    //     formData.append("profile_pic", data.profile_pic);
    //     formData.append("userid", userData.id);

    //     /////////////////// Api call ///////////////////////
    //     var pic_upload_responce = await new ApiService().POST("/new-upload-profile-pic", formData, "file");

    //     // console.log(pic_upload_responce.data)

    //     if (pic_upload_responce.data.status == "1") {
    //         toast.success("Profile picture upload successfully");
    //         await getUserDetails();
    //         reset_2();
    //         setPreviewImage("/images/demo-one.png");
    //     }

    // }

    useEffect(() => {
        const fetchCountries = () => {
            const countryList = Country.getAllCountries();
            const options = countryList.map(item => ({ value: item.isoCode, label: item.name }));

            setCountries(options);
        };

        fetchCountries();
    }, []);


    useEffect(() => {
        fromSetUserDataFromRedux();
    }, [userData])

    return (<>
        {/* ==== Loader ==== */}
        <PreLoader Loader={Loader} />
        {/* ==== Loader ==== */}

        <div className="edit-profile">

            {/* ====== old image upload ======= */}
            {/* <form onSubmit={handleSubmit_2(imgSubmit)}>
                <div>
                    <div className="upload-profile">
                        <img src={PreviewImage} alt="profile" />
                        <label className="form_label">
                            <img src="/images/upload.svg" alt="add-icon" width={30} />
                            <input
                                type="file"
                                className="form-control"
                                id="uploadFile"
                                accept={".jpg,.gif,.png,.jpeg,.svg,.webp"}
                                onChange={uploadPreviewImage}
                            />
                        </label>
                    </div>

                    <p style={{ color: "red" }} className="form-field-error text-center">
                        {errors_2.profile_pic?.message}
                    </p>

                    <div className="section-field text-uppercase text-center mt-2">
                        <button
                            type='submit'
                            className="button  btn-lg btn-theme full-rounded animated right-icn"
                        >
                            <span>
                                Save
                                <i className="glyph-icon flaticon-hearts" aria-hidden="true" />
                            </span>
                        </button>
                    </div>
                </div>
            </form> */}

            <form onSubmit={handleSubmit(submit)}>
                <div>
                    <div className="row mt-4">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Name
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                aria-label="First name"
                                {...register("name")}
                            />

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.name?.message}
                            </p>
                        </div>

                        {/* <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Mobile No.
                            </label>
                            

                            <PhoneInput
                                placeholder="Enter phone number"
                                value={PhoneValue}
                                onChange={setPhoneValue}
                                country={PhoneSelectedCountry}
                                onCountryChange={setPhoneSelectedCountry} />

                            <p style={{ color: "red" }} className="form-field-error">
                                {PhoneValueError}
                            </p>

                        </div> */}

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Email
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Email"
                                aria-label="Last name"
                                readOnly={true}
                                {...register("email")}
                            />

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.email?.message}
                            </p>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Age
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Age"
                                aria-label="Last name"
                                {...register("age")}
                            />

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.age?.message}
                            </p>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Height
                            </label>
                            <select className="form-select" {...register("height")}>
                                <option key={'0'} value="">Select Height</option>
                                {heightData.map((item, index) => (<>
                                    <option key={index + 1} value={item.value}>{item.label}</option>
                                </>))}
                            </select>

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.height?.message}
                            </p>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Country
                            </label>
                            {/* <select
                                className="form-select"
                                aria-label="Default select example"
                                {...register("country")}
                            >
                                <option value="" key={"0"}>Select Country</option>
                                {countries.map((country) => (
                                    <option
                                        key={country.isoCode}
                                        value={country.isoCode}
                                    >
                                        {country.name}
                                    </option>
                                ))}
                            </select> */}

                            <Select
                                options={countries}
                                onChange={(selectedOption) => ChooseCountry(selectedOption)}
                                value={SelectedCountry}
                                className="mt-2"
                            />

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.country?.message}
                            </p>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                City
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="City"
                                aria-label="Last name"
                                {...register("city")}
                            />

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.city?.message}
                            </p>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Zip Code
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Zip Code"
                                aria-label="Last name"
                                {...register("zipcode")}
                            />

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.zipcode?.message}
                            </p>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Gender
                            </label>

                            <div className="radio-buttons">
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="male"
                                        {...register("gender")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Male</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="female"
                                        {...register("gender")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Female</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="other"
                                        {...register("gender")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Other</span>
                                </label>
                            </div>

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.gender?.message}
                            </p>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Marital
                            </label>

                            <div className="radio-buttons">
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="single"
                                        {...register("marital")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Single</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="divorced"
                                        {...register("marital")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Divorced</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="other"
                                        {...register("marital")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Other</span>
                                </label>
                            </div>

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.marital?.message}
                            </p>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Education
                            </label>

                            <div className="radio-buttons">

                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="no_degree"
                                        {...register("education")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">No Degree</span>
                                </label>


                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="high_school_diploma_or_equivalent"
                                        {...register("education")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">
                                        High School Diploma / Equivalent
                                    </span>
                                </label>


                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="some_college"
                                        {...register("education")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Some College</span>
                                </label>


                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="associate_degree"
                                        {...register("education")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Associate Degree</span>
                                </label>


                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="bachelor"
                                        {...register("education")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Bachelor</span>
                                </label>


                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="master_degree"
                                        {...register("education")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Master Degree</span>
                                </label>


                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="doctorate_post_doctorate"
                                        {...register("education")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Doctorate / Post-Doctorate</span>
                                </label>



                            </div>

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.education?.message}
                            </p>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Occupation
                            </label>

                            <div className="radio-buttons">
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="employed"
                                        {...register("occupation")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Employed</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="retired"
                                        {...register("occupation")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Retired</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="student"
                                        {...register("occupation")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Student</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="self-employed"
                                        {...register("occupation")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Self-Employed</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="unemployed"
                                        {...register("occupation")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Unemployed</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="other"
                                        {...register("occupation")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Other</span>
                                </label>
                            </div>

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.occupation?.message}
                            </p>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Body Type
                            </label>

                            <div className="radio-buttons">
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="athletic"
                                        {...register("bodyType")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Athletic</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="average"
                                        {...register("bodyType")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Average</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="petite"
                                        {...register("bodyType")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Petite</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="thick"
                                        {...register("bodyType")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Thick</span>
                                </label>
                            </div>

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.bodyType?.message}
                            </p>
                        </div>


                        {/* ================== demo ===================== */}
                        {/* <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Age
                            </label>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                            >
                                <option selected="">32</option>
                                <option value={1}>33</option>
                                <option value={2}>34</option>
                                <option value={3}>35</option>
                            </select>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Gender
                            </label>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                            >
                                <option selected="">Male</option>
                                <option value={1}>Female</option>
                            </select>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Occupation
                            </label>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                            >
                                <option selected="">Employed</option>
                                <option value={1}>Self Employed</option>
                            </select>
                        </div> */}
                        {/* ================== End demo ===================== */}

                        <div className="section-field text-uppercase text-center mt-2">
                            {" "}
                            <button
                                type='submit'
                                className="button  btn-lg btn-theme full-rounded animated right-icn"
                            >
                                <span>
                                    Save
                                    <i
                                        className="glyph-icon flaticon-hearts"
                                        aria-hidden="true"
                                    />
                                </span>
                            </button>{" "}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </>)
}

export default EditProfile