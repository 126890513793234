import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PreLoader from '../PreLoader'
import ApiService from "../../services/ApiService";
import moment from "moment";

function IncomingCallsHistory() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { userData } = useSelector((state) => state.auth);

    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [incomeing_calllog_details, set_incomeing_calllog_details] = useState([]);



    const callHistoryList = useCallback(async () => {
        setLoader(true);

        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().POST(`/call-history-new`, { userid: userData.id });
        console.log("call-history-new ", responce.data);

        // return;

        if (responce.data.status === "1") {

            set_incomeing_calllog_details(responce.data.incomeing_calllog_details);
        }

        setLoader(false);
    }, [userData.id]);

    useEffect(() => {
        callHistoryList();
    }, []);


    return (<>
        {/* ==== Loader ==== */}
        <PreLoader Loader={Loader} />
        {/* ==== Loader ==== */}




        <div className="connections">


            {/* Form-start */}
            {/* <form action="" className="book-date-tabe">
                <div className="row">
                    <div className="col-md-6">
                        <label class="form-label">Start Date:</label>
                        <input type="date" class="form-control" placeholder="29-12-2024"></input>
                    </div>
                    <div className="col-md-6">
                        <label class="form-label">End Date:</label>
                        <input type="date" class="form-control" placeholder="29-12-2024"></input>
                    </div>
                </div>
            </form> */}
            {/* Form-end */}


            {/* sub-tab-start  */}
           
            <div class="tab-content" id="pills-tabContent">
                <div
                    className="tab-pane fade show active"
                    id="incomingcall"
                    role="tabpanel"
                    aria-labelledby="incoming-call-tab"
                    tabindex="0"
                >
                    {/*  incomeing_calllog_details  */}
                    {incomeing_calllog_details.map((item, index) => (<>
                        <div className="profile" key={index} onClick={() => navigate("/view-profile", {
                            state: {
                                profile_id: item.senderid,
                                page_show_permission: true
                            }
                        })}>
                            <img className="img-fluid me-2" src={item.profile_pic_pro_resize_200_url} alt="" />
                            <div className="details">
                                <h2>{item.name}</h2>
                                <p> {item.call_type === "videocall" ? "Video Call " : "Audio Call "}</p>
                                <p> Amount: ${item.amount}</p>
                                <p> Call Duration: {Math.ceil(Number(item.call_duration) / 60)} min</p>
                                {/* <p> Date: {moment.unix(item.datetime).fromNow()}</p> */}
                                <p> Date: {moment.unix(item.datetime).format('MM/DD/YYYY')}</p>

                            </div>
                        </div>
                    </>))}
                </div>
            </div>
            {/* sub-tab-end  */}









        </div>

    </>)
}

export default memo(IncomingCallsHistory);