import axios from 'axios';
const REACT_APP_API_SERVICE_URL = process.env.REACT_APP_API_SERVICE_URL;



class ApiService {

    // constructor(){
    //     this.token=localStorage.getItem("token");
    // }


    GET(url) {

        const header = {
            headers: {
                "Content-Type": "application/json",
                // "Authorization": 'Bearer ' + this.token,
            }
        };

        var finalUrl = REACT_APP_API_SERVICE_URL + url;

        return axios.get(finalUrl, header);
    }

    POST(url, payload, h_tag = "json") {

        const header = {
            headers: {
                "Content-Type": (h_tag === "json" ? "application/json" : "multipart/form-data"),
                // "Authorization": 'Bearer ' + this.token,
            }
        };

        var finalUrl = REACT_APP_API_SERVICE_URL + url;

        return axios.post(finalUrl, payload, header);
    }

}

export default ApiService;
