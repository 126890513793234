import { useNavigate, useLocation } from "react-router-dom";
import ApiService from "../../services/ApiService";
import { useEffect, useState } from "react";
import { Country } from 'country-state-city';
import { push, ref, serverTimestamp, set } from "firebase/database";
import { firebase_db } from '../../FirebaseSetup';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import PreLoader from "../PreLoader";
import Swal from 'sweetalert2';
import { authActions } from "../../redux/auth";
import { newRoomIdGet } from "../../CommonFunc";
import { Modal, FloatingLabel, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReportSchama } from "../../Schemas";
import { Rating } from 'react-simple-star-rating';
import { Tooltip as ReactTooltip } from "react-tooltip";
// import 'react-tooltip/dist/react-tooltip.css';

const education_obj = {
  "no_degree": "No Degree",
  "high_school_diploma_or_equivalent": "High School Diploma / Equivalent",
  "some_college": "Some College",
  "associate_degree": "Associate Degree",
  "bachelor": "Bachelor",
  "master_degree": "Master Degree",
  "doctorate_post_doctorate": "Doctorate / Post-Doctorate"
}


const bodyType_obj = {
  "athletic": "Athletic",
  "average": "Average",
  "petite": "Petite",
  "thick": "Thick"
}

const heightData = {
  "5.0": 'Under 5’0"',
  "5.1": '5’1"',
  "5.2": '5’2"',
  "5.3": '5’3"',
  "5.4": '5’4"',
  "5.5": '5’5"',
  "5.6": '5’6"',
  "5.7": '5’7"',
  "5.8": '5’8"',
  "5.9": '5’9"',
  "6.0": '6’0"',
  "6.1": '6’1"',
  "6.2": '6’2"',
  "6.3": '6’3"',
  "6.4": '6’4"',
  "6.5": '6’5"',
  "6.6": '6’6"',
  "6.7": '6’7"',
  "6.8": '6’8"',
  "6.9": '6’9"',
  "7.0": '7’0"',
  "7.1": '7’1"',
  "7.2": '7’2"',
  "7.3": '7’3"',
  "7.4": '7’4"',
  "7.5": '7’5"',
}


const gender_obj = {
  "male": "Male",
  "female": "Female",
  "other": "Other",
}

const occupation_obj = {
  "employed": "Employed",
  "retired": "Retired",
  "student": "Student",
  "self-employed": "Self-Employed",
  "unemployed": "Unemployed",
  "other": "Other",
}



function index() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { userData } = useSelector(state => state.auth);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [Profile, setProfile] = useState({});

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
  const [Loader, setLoader] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
  const [ConnectionStatus, setConnectionStatus] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
  const [Follow, setFollow] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
  const [BlockStatus, setBlockStatus] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
  const [RatingStar, setRatingStar] = useState(0);

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
  const [RatingUser, setRatingUser] = useState(0);

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
  const [CallChargeData, setCallChargeData] = useState({});

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
  const [ShowReportModal, setShowReportModal] = useState(false);
  const {
    register: report_register,
    handleSubmit: report_handleSubmit,
    reset: report_reset,
    // setValue,
    // getValues,
    // trigger,
    formState: { errors: report_errors },
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useForm({
    resolver: yupResolver(ReportSchama),
    mode: 'all'
  });


  ///////////// at first call /////////////
  const userInfo = async () => {

    setLoader(true);

    let new_data = {
      userid: userData.id,
      profile_id: location.state.profile_id,
    }

    /////////////////// Api call ///////////////////////
    var responce = await new ApiService().POST("/search-profile-id", new_data);

    console.log("Profile ", responce.data)
    // console.log("userData.wallet_amount ", userData.wallet_amount);

    if (responce.data.status === "1") {
      setProfile(responce.data.userdetails[0]);
    } else {
      setProfile({})
      toast.error("Sorry! something went wrong")
      navigate(-1);
    }

    /////////////////// Api call ///////////////////////
    var wishResponce = await new ApiService().POST(`/get-wishlist`, { userid: userData.id, profile_id: location.state.profile_id });
    console.log("get-wishlist ", wishResponce.data);

    if (wishResponce.data.status === '1') {
      setFollow(true);
    }
    else {
      setFollow(false);
    }

    /////////////////// Api call ///////////////////////
    var connResponce = await new ApiService().GET(`/connection-list?user_id=${userData.id}&connection_user_id=${location.state.profile_id}`);
    console.log("connection-list ", connResponce.data);

    if (connResponce.data.con_status === true) {
      setConnectionStatus(true);
    }
    else {
      setConnectionStatus(false);
    }


    /////////////////// Api call ///////////////////////
    var blockResponce = await new ApiService().POST(`/get-user-block-status`, {
      sender_id: userData.id,
      receiver_id: location.state.profile_id
    });
    console.log("get-user-block-status ", blockResponce.data);

    if (blockResponce.data.block_status === true) {
      setBlockStatus(true);
    }
    else {
      setBlockStatus(false);
    }



    /////////////////// Api call ///////////////////////
    var rateResponce = await new ApiService().POST(`/get-total-rating`, {
      userid: location.state.profile_id,
    });
    console.log("get-total-rating ", rateResponce.data);

    if (rateResponce.data.status === "1") {
      setRatingStar(rateResponce.data.rating);
      setRatingUser(rateResponce.data.usercount);
    }
    else {
      setRatingStar(0);
      setRatingUser(0);
    }


    /////////////////// Api call ///////////////////////
    var callFeesResponce = await new ApiService().GET(`/get-all-fees`);
    console.log("get-all-fees ", callFeesResponce.data);


    if (callFeesResponce.data.status === "1") {
      setCallChargeData(callFeesResponce.data.data)
    } else {
      setCallChargeData({});
    }



    setLoader(false);

  };



  ///////////// chat,audio and video call ////////////////////////
  const CheckCallSendOrNot = async (call_type) => {

    /////////////////// Api call ///////////////////////
    var callFeesResponce = await new ApiService().GET(`/get-all-fees`);
    console.log("get-all-fees ", callFeesResponce.data);

    let fees = 0;

    if (call_type === "audiocall") {
      fees = Number(callFeesResponce.data.data.audiocall.charges);
    }
    else if (call_type === "videocall") {
      fees = Number(callFeesResponce.data.data.videocall.charges);
    }

    let ratePerMinute = fees;

    let sender_amount = Number(userData.wallet_amount);
    let receiver_amount = Number(Profile.wallet_amount);

    ///// Calculate the sender total minutes ////
    let senderTotalMinutes = Math.round(sender_amount / ratePerMinute);

    ///// Calculate the receiver total minutes ////
    let receiverTotalMinutes = Math.round(receiver_amount / ratePerMinute);

    ////// sender check have minium 5 minutes balance ////
    if (senderTotalMinutes < 5) {
      toast.warning("You don't have sufficient balance to call")
      return false;
    }
    ////// receiver check have minium 1 minutes balance ////
    else if (receiverTotalMinutes < 1) {
      toast.warning("This user unable to take your call")
      return false;
    }

    return true;
  }

  const CalculateTotalCallMinutes = async (call_type) => {

    /////////////////// Api call ///////////////////////
    var callFeesResponce = await new ApiService().GET(`/get-all-fees`);
    console.log("get-all-fees ", callFeesResponce.data);

    let fees = 0;

    if (call_type === "audiocall") {
      fees = Number(callFeesResponce.data.data.audiocall.charges);
    }
    else if (call_type === "videocall") {
      fees = Number(callFeesResponce.data.data.videocall.charges);
    }

    let sender_amount = Number(userData.wallet_amount);
    let receiver_amount = Number(Profile.wallet_amount);
    let ratePerMinute = fees;

    var amount = 0;

    ///// compare which amount small or equal ///
    if (sender_amount === receiver_amount) {
      amount = sender_amount;
    } else {

      if (sender_amount < receiver_amount) {
        amount = sender_amount;
      }
      else if (receiver_amount < sender_amount) {
        amount = receiver_amount;
      }

    }

    ///// Calculate the total minutes ////
    const totalSeconds = ((Math.round(amount / ratePerMinute)) * 60);

    // console.log("totalSeconds ", totalSeconds)

    return {
      sender_amount,
      receiver_amount,
      totalSeconds,
      ratePerMinute,
    }

  }

  const EnterChatORrequestSend = async () => {

    ////////////////// Check chat plan have /////////////
    /////////////////// Api call ///////////////////////
    var response = await new ApiService().GET(`/check-user-chat-plan-status/${userData.id}`);
    console.log("check-user-chat-plan-status ", response.data);

    if (response.data.status !== '1') {
      toast.warning("Please Buy Chat Plan");
      navigate("/profile");
      return;
    }

    /////////////////// Api call ///////////////////////
    var responce = await new ApiService().GET(`/get-chat-request?sender_id=${userData.id}&receiver_id=${location.state.profile_id}`);

    if (responce.data.status === '1') {
      navigate("/chat", {
        state: {
          receiver_id: location.state.profile_id,
        }
      });
    }
    else {

      //  ===== Get a key for a new Post. ===== //
      // const newPostKey = push(ref(firebase_db)).key;
      // console.log(newPostKey)

      var roomId = await newRoomIdGet();

      set(ref(firebase_db, `/chatrooms/${roomId}`), {
        key: roomId,
        sender_id: userData.id,
        receiver_id: location.state.profile_id,
        created_at: serverTimestamp(),
        status: "pending", // 'pending', 'accept', 'reject'
        messages: []
      });

      let new_data = {
        room_id: roomId,
        sender_id: userData.id,
        receiver_id: location.state.profile_id,
        status: "pending"
      }

      /////////////////// Api call ///////////////////////
      var responce = await new ApiService().POST(`/add-chat-request`, new_data);


      if (responce.data.status === '1') {
        navigate("/chat", {
          state: {
            receiver_id: location.state.profile_id,
          }
        });
      }

    }
  }

  const EnterAudioCallRrequestSend = () => {

    Swal.fire({
      title: `Audio Call charges $${CallChargeData?.audiocall?.charges?.toFixed(2)}/min`,
      showCancelButton: true,
      confirmButtonText: "Continue",
    }).then(async (result) => {
      if (result.isConfirmed) {

        setLoader(true);
        let new_data = {
          userid: location.state.profile_id,
        }
        /////////////////// Api call ///////////////////////
        var userBusyResponce = await new ApiService().POST(`/get-pnotify-key`, new_data);
        console.log("userBusyResponce ", userBusyResponce.data)

        if (userBusyResponce.data.status === '1') {

          if (userBusyResponce.data.loginstatus !== "1") {
            toast.warning("User offline");
            setLoader(false);
            return;
          }

          let checkCallSendOrNot = await CheckCallSendOrNot("audiocall");
          if (!checkCallSendOrNot) {
            setLoader(false);
            return;
          }

          //  ===== Get a key for a new Post. ===== //
          // const newPostKey = push(ref(firebase_db)).key;
          // console.log(newPostKey)
          var roomId = await newRoomIdGet();


          let new_data = {
            genkey: roomId,
            senderid: userData.id,
            receiverid: location.state.profile_id,
            starttime: moment().unix(),
            endtime: moment().add(1.1, 'minutes').unix() /// this time will be dynamic with wallet amount
          }

          /////////////////// Api call ///////////////////////
          var responce = await new ApiService().POST(`/send-request`, new_data);
          console.log("send-request ", responce.data)

          if (responce.data.status === '1') {

            if (responce.data.block_count > 0) {
              toast.warning("User blocked you!");
              setLoader(false);
              return;
            }


            //////////////// Calculate total call minutes /////////////
            var callObj = await CalculateTotalCallMinutes("audiocall");
            console.log("callObj", callObj);


            set(ref(firebase_db, `/user_video_audio_call_requests/${roomId}`), {
              key: roomId,
              sender_id: userData.id,
              receiver_id: location.state.profile_id,
              created_at: serverTimestamp(),
              call_type: 'audio',
              notificationid: responce.data.notification_id,
              status: "pending", // 'pending','accept','reject','complete'
              rating_status: "pending",
              totalSeconds: callObj.totalSeconds,
              ratePerMinute: callObj.ratePerMinute,
              sender_details: {
                profile_pic_pro_resize_200_url: userData.profile_pic_pro_resize_200_url,
                name: userData.name,
                gender: userData.gender,
                wallet_amount: userData.wallet_amount
              },
              receiver_details: {
                profile_pic_pro_resize_200_url: Profile.profile_pic_pro_resize_200_url,
                name: Profile.name,
                gender: Profile.gender,
                wallet_amount: Profile.wallet_amount
              },
            });


            // navigate("/audio-call", {
            //   state: {
            //     page_access: true,
            //     room_id: roomId,
            //   }
            // });
          } else {
            toast.warning(responce.data.message);
          }



        } else {
          toast.warning(userBusyResponce.data.message);
        }
        setLoader(false);

      }
    })



  }


  const EnterVideoCallRrequestSend = () => {

    Swal.fire({
      title: `Video Call charges $${CallChargeData?.videocall?.charges?.toFixed(2)}/min`,
      showCancelButton: true,
      confirmButtonText: "Continue",
    }).then(async (result) => {
      if (result.isConfirmed) {

        setLoader(true);

        let new_data = {
          userid: location.state.profile_id,
        }
        /////////////////// Api call ///////////////////////
        var userBusyResponce = await new ApiService().POST(`/get-pnotify-key`, new_data);
        console.log("userBusyResponce ", userBusyResponce.data)

        if (userBusyResponce.data.status === '1') {

          if (userBusyResponce.data.loginstatus !== "1") {
            toast.warning("User offline");
            setLoader(false);
            return;
          }

          let checkCallSendOrNot = await CheckCallSendOrNot("videocall");
          if (!checkCallSendOrNot) {
            setLoader(false);
            return;
          }

          //  ===== Get a key for a new Post. ===== //
          // const newPostKey = push(ref(firebase_db)).key;
          // console.log(newPostKey)
          var roomId = await newRoomIdGet();


          let new_data = {
            genkey: roomId,
            senderid: userData.id,
            receiverid: location.state.profile_id,
            starttime: moment().unix(),
            endtime: moment().add(1.10, 'minutes').unix() /// this time will be dynamic with wallet amount
          }

          /////////////////// Api call ///////////////////////
          var responce = await new ApiService().POST(`/send-request`, new_data);
          console.log("send-request ", responce.data)

          if (responce.data.status === '1') {

            if (responce.data.block_count > 0) {
              toast.warning("User blocked you!");
              setLoader(false);
              return;
            }

            //////////////// Calculate total call minutes /////////////
            var callObj = await CalculateTotalCallMinutes("videocall");
            console.log("callObj", callObj);

            set(ref(firebase_db, `/user_video_audio_call_requests/${roomId}`), {
              key: roomId,
              sender_id: userData.id,
              receiver_id: location.state.profile_id,
              created_at: serverTimestamp(),
              call_type: 'video',
              notificationid: responce.data.notification_id,
              status: "pending", // 'pending','accept','reject','complete'
              rating_status: "pending",
              totalSeconds: callObj.totalSeconds,
              ratePerMinute: callObj.ratePerMinute,
              sender_details: {
                profile_pic_pro_resize_200_url: userData.profile_pic_pro_resize_200_url,
                name: userData.name,
                gender: userData.gender,
                wallet_amount: userData.wallet_amount
              },
              receiver_details: {
                profile_pic_pro_resize_200_url: Profile.profile_pic_pro_resize_200_url,
                name: Profile.name,
                gender: Profile.gender,
                wallet_amount: Profile.wallet_amount
              },
            });


            // navigate("/audio-call", {
            //   state: {
            //     page_access: true,
            //     room_id: roomId,
            //   }
            // });
          } else {
            toast.warning(responce.data.message);
          }



        } else {
          toast.warning(userBusyResponce.data.message);
        }

        setLoader(false);


      }
    });



  }


  ///////////// chat,audio and video call ////////////////////////

  const chkConnectionReq = async () => {

    setLoader(true);

    /////////////////// Api call ///////////////////////
    var connResponce = await new ApiService().GET(`/connection-list?user_id=${userData.id}&connection_user_id=${Profile.id}`);
    console.log("connection-list ", connResponce.data);

    if (connResponce.data.con_status === false) {

      Swal.fire({
        title: `Access photos gallery for $${connResponce.data.connection_price}`,
        showCancelButton: true,
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          sendConnectionReq();
        }
      });

    }
    else if (connResponce.data.con_status === true) {
      navigate("/gallery", {
        state: {
          id: Profile.id
        }
      });
    }

    setLoader(false);

  }


  const sendConnectionReq = async () => {

    setLoader(true);

    let new_data = {
      user_id: userData.id,
      connection_user_id: Profile.id,
    }

    /////////////////// Api call ///////////////////////
    var connResponce = await new ApiService().POST(`/connection-req-send`, new_data);
    console.log("connection-req-send ", connResponce.data);

    if (connResponce.data.status === '1') {
      toast.success(connResponce.data.message);
      setConnectionStatus(true);

      await getLoginUserDetails();

      navigate("/gallery", {
        state: {
          id: Profile.id
        }
      });

    }
    else if (connResponce.data.status === '2') {
      toast.warning(connResponce.data.message);
      navigate('/profile', { state: { tab_name: 'funds' } })
    }

    setLoader(false);

  }


  const getLoginUserDetails = async () => {

    setLoader(true);

    /////////////////// Api call ///////////////////////
    var responce = await new ApiService().POST("/user-info", { userid: userData.id });

    var resData = responce.data.user_details[0];
    // console.log("getUserDetails ", resData);
    dispatch(authActions.UpdateUserData(resData));

    setLoader(false);

  }


  const DeleteWishListConfirm = async () => {

    if (Follow) {
      Swal.fire({
        title: `Do you want to remove the user from wishlist?`,
        showCancelButton: true,
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          AddRemoveWishlist();
        }
      });
    }
    else {
      AddRemoveWishlist();
    }

  }

  const AddRemoveWishlist = async () => {

    let new_data = {
      userid: userData.id,
      profile_id: Profile.id,
    };

    /////////////////// Api call ///////////////////////
    var responce = await new ApiService().POST("/add_remove_wishlist", new_data);
    console.log("add_remove_wishlist ", responce.data);

    if (responce.data.status === '1') {

      /////////////////// Api call ///////////////////////
      var wishResponce = await new ApiService().POST(`/get-wishlist`, { userid: userData.id, profile_id: location.state.profile_id });
      console.log("get-wishlist ", wishResponce.data);

      if (wishResponce.data.status === '1') {
        toast.success('Successfully added to wishlist');
        setFollow(true);
      }
    } else {
      toast.info('Successfully remove to wishlist');
      setFollow(false);
    }

  }


  const BlockUnblockPopup = async () => {
    if (!BlockStatus) {
      Swal.fire({
        title: `Do you want to block this user?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          BlockUnblockUser();
        }
      });
    }
    else {
      BlockUnblockUser();
    }
  }


  const BlockUnblockUser = async () => {

    let new_data = {
      sender_id: userData.id,
      receiver_id: Profile.id,
    };

    /////////////////// Api call ///////////////////////
    var responce = await new ApiService().POST("/block-unblock-user", new_data);
    console.log("block-unblock-user ", responce.data);

    if (responce.data.status === '1') {

      if (responce.data.block_status) {
        toast.success('User has been blocked Successfully');
        setBlockStatus(true);
      }
      else {
        toast.success('User has been unblocked Successfully');
        setBlockStatus(false);
      }

    }

  }


  const ReportSubmit = async (data) => {

    let new_data = {
      sender_id: userData.id,
      receiver_id: Profile.id,
      report_reason: data.report_reason,
      report_comment: `Report By ${userData.name}`
    };

    /////////////////// Api call ///////////////////////
    var responce = await new ApiService().POST("/send-report", new_data);
    console.log("send-report ", responce.data);

    if (responce.data.status === '1') {
      toast.success(responce.data.message);
    }
    else if (responce.data.status === '2') {
      toast.info(responce.data.message);
    }

    report_reset();

    setShowReportModal(false);

  }

  const CountryName = (code) => {
    // alert(code)
    if (code !== undefined) {
      var data = Country.getCountryByCode(code);
      // console.log(data)
      return data.name
    } else {
      return;
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    // console.log(location)

    userInfo();

  }, [])

  return (
    <>

      {/* ==== Loader ==== */}
      <PreLoader Loader={Loader} />
      {/* ==== Loader ==== */}

      <section className="view-profile-section">
        <div className="view-profile-bg">
          <div>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="px-3">
          <div className="content">

            <div className="block-report">
              <div className="position-relative">
                <i class="fa-solid fa-ellipsis-vertical"></i>

                <ul>
                  <li><button type="button" onClick={BlockUnblockPopup} >{BlockStatus ? "UnBlock" : "Block"}</button></li>
                  <li><button type="button" onClick={() => setShowReportModal(true)}>Report</button></li>
                </ul>

              </div>
            </div>



            <div className="content__avatar">
              {/* <img src={Profile.profile_pic_pro_resize_url} alt="content__avatar" /> */}
              <img src={Profile.profile_pic_pro_resize_200_url} alt="content__avatar" />
            </div>
            <div className="content__title">
              <h2>{Profile.name}</h2>
              <span>{Profile.city}, {CountryName(Profile.country)}</span>

              <ul>
                <li>
                  <div className="age-text">
                    <strong>Age:{Profile.age}</strong>
                    {Profile.login_status == "0" ? (<span className="offline">Offline</span>) : (<span className="online">Online</span>)}
                  </div>

                  <div className="choose">
                    {" "}

                    <img
                      className="img-fluid"
                      src={Follow ? '/images/team/Profile-love-icon.svg' : '/images/team/profile-like.svg'}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={DeleteWishListConfirm}
                      data-tooltip-id="wishlist_tooltip"
                    />

                    {(Profile.galleryImageCount !== 0) && <img
                      className="img-fluid"
                      src={'/images/team/show-img.svg'}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={chkConnectionReq}
                      data-tooltip-id="photo_tooltip"
                    />}




                  </div>
                </li>
                <li><h6>Gender:</h6> <span className="sharp-r">{gender_obj[Profile.gender]}</span></li>
                <li> <h6>Occupation:</h6> <span className="sharp-r">{occupation_obj[Profile.occupation]}</span> </li>
                <li> <h6>Education:</h6> <span className="sharp-r">{education_obj[Profile.education]}</span> </li>
                <li> <h6>Height:</h6> <span className="sharp-r">{heightData[Profile.height]}</span> </li>
                <li> <h6>Body Type:</h6> <span className="sharp-r">{bodyType_obj[Profile.bodyType]}</span> </li>
                <li> <h6>Match Percentage:</h6> <span className="sharp-r">{Profile.matchpercent}%</span> </li>
                <li> <h6>Distance:</h6> <span className="sharp-r">{Profile?.distancedata_2?.miles} miles</span> </li>

                <li> <h6>Rating:</h6> <span className="sharp-r d-flex align-items-center"><Rating className="profile_rating_star" initialValue={Number(RatingStar)} iconsCount={5} allowFraction={true} readonly={true} />  ({RatingStar})</span> </li>

                {(RatingUser !== 0) && <li className="justify-content-end"> <span className="sharp-r  "> ({RatingUser}) User </span> </li>}

                <li> <h6>Connection Status:</h6>  {ConnectionStatus ? <span className="connect">Connected</span> : <span className="connect">Not Connected</span>} </li>
                <li>
                  {/* <button><img className="img-fluid" src={sms_Icon} alt="" /></button> */}
                  <button type="button" onClick={EnterChatORrequestSend} >
                    <img className="img-fluid" src={'/images/team/sms-icon.svg'} alt="" />
                  </button>
                  <button type="button" onClick={EnterVideoCallRrequestSend}>
                    <img className="img-fluid" src={'/images/team/video-icon.svg'} alt="" />
                  </button>
                  <button type="button" onClick={EnterAudioCallRrequestSend}>
                    <img className="img-fluid" src={'/images/team/call-iconn.svg'} alt="" />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>


      {/* ///////////// Reciver Call Modal ///////////////// */}
      <Modal
        show={ShowReportModal}
        className="reportModal"
        backdrop="static"
        keyboard={false}>
        <Modal.Header>
          <Modal.Title>Report Comments</Modal.Title>
        </Modal.Header>
        <Form onSubmit={report_handleSubmit(ReportSubmit)}>
          <Modal.Body>

            <Form.Group>
              <Form.Control
                as="textarea"
                placeholder="Leave a comment here"
                style={{ height: '100px' }}
                {...report_register("report_reason")}
                isInvalid={!!report_errors.report_reason}
              />

              <Form.Control.Feedback type="invalid">
                {report_errors.report_reason?.message}
              </Form.Control.Feedback>
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary">
              Send
            </button>
            <button className="btn btn-primary" onClick={() => setShowReportModal(false)}>
              Cancel
            </button>
          </Modal.Footer>
        </Form>
      </Modal>

      <ReactTooltip
        id="wishlist_tooltip"
        place="bottom"
        content="Wishlist"
        style={{ zIndex: 999999 }}
      />

      <ReactTooltip
        id="photo_tooltip"
        place="bottom"
        content="Gallery Photos"
        style={{ zIndex: 999999 }}
      />
    </>
  );
}

export default index;
