import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import PreLoader from "../PreLoader";
import moment from "moment";

function Index() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { userData } = useSelector((state) => state.auth);

    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Items, setItems] = useState([]);

    const getNotificationList = useCallback(async () => {
        setLoader(true);

        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().GET(`/get-notifications/${userData.id}`);
        console.log("get-notifications ", responce.data);

        // return;

        if (responce.data.status === "1") {
            setItems(responce.data.data);
        }

        setLoader(false);
    }, []);

    useEffect(() => {
        getNotificationList();
    }, []);

    return (
        <>
            {/* ==== Loader ==== */}
            <PreLoader Loader={Loader} />
            {/* ==== Loader ==== */}
            <div className="connections">
                <h1>My Notifications</h1>

                {Items.map((item, index) => (<>
                    <div className="profile" key={index}>
                        <img className="img-fluid me-2" src={item.profile_pic_pro_resize_200_url} alt="" />
                        <div className="details">
                            <h2>{item.name}</h2>
                            <p>{item.message}</p>
                        </div>

                        <p>{moment(item.notfication_time).format("DD/MM/YYYY")}</p>
                        
                    </div>
                </>))}


                {(Items.length === 0) && (<>
                    <div className="profile">
                        <div className="details">
                            <h2>NO Notifications Found</h2>
                        </div>

                    </div>
                </>)}


            </div>
        </>
    );
}

export default memo(Index);
