import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import PreLoader from "../PreLoader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChangePasswordSchama } from "../../Schemas";
import ApiService from '../../services/ApiService';
import { toast } from "react-toastify";

function ChangePassword() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { userData } = useSelector(state => state.auth);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [PasswordShow1, setPasswordShow1] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [PasswordShow2, setPasswordShow2] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [PasswordShow3, setPasswordShow3] = useState(false);



    // /////////////// Form value ////////////////////
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        // getValues,
        // trigger,
        // control,
        formState: { errors },
        // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useForm({
        resolver: yupResolver(ChangePasswordSchama),
        mode: 'all'
    });


    const submit = async (data) => {
        setLoader(true);

        data.userid = userData.id;

        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().POST("/change-password", data);


        if (responce.data.status == '1') {
            toast.success(responce.data.message);
        } else if (responce.data.status == '2') {
            toast.warning(responce.data.message);
        } else {
            console.log(responce.data.error)
        }

        reset();

        setLoader(false);
    }



    return (<>
        {/* ==== Loader ==== */}
        <PreLoader Loader={Loader} />
        {/* ==== Loader ==== */}


        <div className="change-password">
            <h2>Change Password</h2>
            <div className="password-container">
                <form onSubmit={handleSubmit(submit)}>
                    <div className="col-12 mb-3 pas-ic">
                        <label htmlFor="inputEmail4" className="form-label">
                            Old Password
                        </label>
                        <input
                            type={PasswordShow1 ? "text" : "password"}
                            className="form-control"
                            placeholder="Old Password"
                            aria-label="First name"
                            {...register("old_password")}
                        />

                        {PasswordShow1 ? <i className="show-hide-pass fa fa-eye" onClick={() => setPasswordShow1(false)} /> : <i className="show-hide-pass fa fa-eye-slash" onClick={() => setPasswordShow1(true)} />}

                        <p style={{ color: "red" }} className="form-field-error">
                            {errors.old_password?.message}
                        </p>
                    </div>
                    <div className="col-12 mb-3 pas-ic">
                        <label htmlFor="inputEmail4" className="form-label">
                            New Password
                        </label>
                        <input
                            type={PasswordShow2 ? "text" : "password"}
                            className="form-control"
                            placeholder="New Password"
                            aria-label="First name"
                            {...register("new_password")}
                        />

                        {PasswordShow2 ? <i className="show-hide-pass fa fa-eye" onClick={() => setPasswordShow2(false)} /> : <i className="show-hide-pass fa fa-eye-slash" onClick={() => setPasswordShow2(true)} />}

                        <p style={{ color: "red" }} className="form-field-error">
                            {errors.new_password?.message}
                        </p>
                    </div>
                    <div className="col-12 mb-3 pas-ic">
                        <label htmlFor="inputEmail4" className="form-label">
                            Confirm Password
                        </label>
                        <input
                            type={PasswordShow3 ? "text" : "password"}
                            className="form-control"
                            placeholder="Confirm Password"
                            aria-label="First name"
                            {...register("confirm_password")}
                        />

                        {PasswordShow3 ? <i className="show-hide-pass fa fa-eye" onClick={() => setPasswordShow3(false)} /> : <i className="show-hide-pass fa fa-eye-slash" onClick={() => setPasswordShow3(true)} />}

                        <p style={{ color: "red" }} className="form-field-error">
                            {errors.confirm_password?.message}
                        </p>
                    </div>
                    <div className="section-field text-uppercase  mt-2">
                        <button className="button  btn-lg btn-theme full-rounded animated right-icn"
                            type='submit'>
                            <span>
                                Save
                                <i className="glyph-icon flaticon-hearts" aria-hidden="true" />
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </>)
}

export default ChangePassword