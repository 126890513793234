import React, { useCallback, useEffect, useState } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    Sidebar,
    Search,
    ConversationList,
    Conversation,
    ConversationHeader,
    Avatar,
    VoiceCallButton,
    VideoCallButton,
    TypingIndicator,
    MessageSeparator,
} from "@chatscope/chat-ui-kit-react";

import { useSelector } from "react-redux";
import { child, onValue, push, ref, serverTimestamp, set, get, update } from "firebase/database";
import { firebase_db } from '../../FirebaseSetup';
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import ApiService from "../../services/ApiService";
import { useNavigate } from "react-router-dom";
import PreLoader from "../PreLoader";


function Chat({ location }) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { userData } = useSelector(state => state.auth);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const location = useLocation();

    // Set initial message input value to empty string
    const [messageInputValue, setMessageInputValue] = useState("");
    const [ActiveConversation, setActiveConversation] = useState({});
    const [SidebarList, setSidebarList] = useState([]);
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [sidebarStyle, setSidebarStyle] = useState({});
    const [chatContainerStyle, setChatContainerStyle] = useState({});
    const [conversationContentStyle, setConversationContentStyle] = useState({});
    const [conversationAvatarStyle, setConversationAvatarStyle] = useState({});
    const [messages, setMessages] = useState([]);
    const [msgLoading, setMsgLoading] = useState(false);
    const [MessageInputDisable, setMessageInputDisable] = useState(false);
    const [ShowConfirmAlert, setShowConfirmAlert] = useState(false); ///// for pending req ////
    const [UserRejectReq, setUserRejectReq] = useState(false); ///// for reject req ////
    const [SelectPackages, setSelectPackages] = useState({});

    const handleBackClick = () => setSidebarVisible(!sidebarVisible);

    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);

    const handleConversationClick = (data) => {
        if (sidebarVisible) {
            setSidebarVisible(false);
            setMessages([]);
        }
        // Conversation Active
        setActiveConversation(data);

    };


    const FirstLoadAndSelect = useCallback(async () => {

        // ////////////////// sidebar data set ///////////
        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().GET(`/get-chat-request?sender_id=${userData.id}&status=pending,accept,reject`);

        console.log("/get-chat-request ", responce.data.data)

        if (responce.data.status === "1") {


            let chatuser = [];
            for (var user of responce.data.data) {

                const snapshot = await get(ref(firebase_db, `chatrooms/${user.room_id}`),);

                if (snapshot.exists()) {

                    let myChatroom = snapshot.val();

                    let myChatroomMessage = [];

                    if (myChatroom.hasOwnProperty("messages")) {
                        if (myChatroom.messages.length > 0) {
                            myChatroomMessage = myChatroom.messages;
                        }
                        else {
                            myChatroomMessage = [];
                        }
                    }

                    let msg_count = 0;

                    console.log("myChatroomMessage ", myChatroomMessage)
                    if (myChatroomMessage.length > 0) {

                        for (var item of myChatroomMessage) {
                            if (item.sender_id == user.receiver_id) {
                                if (item.seen == false) {

                                    user.seen = item.seen
                                    msg_count = msg_count + 1;
                                    user.createdAt = item.createdAt;
                                    user.text = item.text;
                                }

                            }
                        }

                        user.msg_count = msg_count;

                    }

                    console.log("FirstLoadAndSelect", user)
                    chatuser.push(user);


                }

            }

            setSidebarList(chatuser);
            console.log("chatuser ", chatuser);
        }

        if (location.state !== null) {
            // setActiveConversation({
            //     name: "Emily",
            //     img: "https://chatscope.io/storybook/react/static/media/lilly.62d4acff.svg",
            //     user_id: 1,
            //     room_id: "room_1",
            //     status: "available"
            // });
            // setSidebarVisible(false);

            /////////////////// Api call ///////////////////////
            var responce = await new ApiService().GET(`/get-chat-request?sender_id=${userData.id}&receiver_id=${location.state.receiver_id}`);

            if (responce.data.status === "1") {

                setActiveConversation(responce.data.data[0]);
                setSidebarVisible(false);
            }
        } else {

            ////////// first time open and show chat packages /////////
            if (((location.state === null) && (Object.keys(ActiveConversation).length === 0))) {

                /////////////////// Api call ///////////////////////
                var response = await new ApiService().GET(`/check-user-chat-plan-status/${userData.id}`);
                console.log("check-user-chat-plan-status ", response.data);

                if (response.data.status === '1') {
                    setSelectPackages(response.data);
                    setSidebarVisible(false);
                }

            }
        }
    }, [location.state, userData.id]);


    const SidebarAutoLoad = useCallback(async () => {

        // ////////////////// sidebar data set ///////////
        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().GET(`/get-chat-request?sender_id=${userData.id}&status=pending,accept,reject`);

        console.log("/get-chat-request ", responce.data.data)

        if (responce.data.status === "1") {


            let chatuser = [];
            for (var user of responce.data.data) {

                const snapshot = await get(ref(firebase_db, `chatrooms/${user.room_id}`),);

                if (snapshot.exists()) {

                    let myChatroom = snapshot.val();
                    // console.log("myChatroomMessage111111 ", myChatroom.sender_id)

                    let myChatroomMessage = [];

                    if (myChatroom.hasOwnProperty("messages")) {

                        if (myChatroom.messages.length > 0) {
                            myChatroomMessage = myChatroom.messages;
                        }
                        else {
                            myChatroomMessage = [];
                        }
                    }

                    let msg_count = 0;

                    console.log("myChatroomMessage ", myChatroomMessage)
                    if (myChatroomMessage.length > 0) {

                        for (var item of myChatroomMessage) {
                            if (item.sender_id == user.receiver_id) {
                                if (item.seen == false) {

                                    user.seen = item.seen
                                    msg_count = msg_count + 1;
                                    user.createdAt = item.createdAt;
                                    user.text = item.text;
                                }

                            }
                        }

                        user.msg_count = msg_count;

                    }

                    console.log("SidebarAutoLoad", user)
                    chatuser.push(user);
                }


            }

            setSidebarList(chatuser);
            console.log("chatuser ", chatuser);
        }

    }, [userData.id]);


    const fetchMessages = useCallback(async () => {

        const snapshot = await get(child(ref(firebase_db), `/chatrooms/${ActiveConversation.room_id}`));

        return snapshot.val();
    }, [ActiveConversation]);

    const sendMessage = async (input_text) => {
        // alert(input_text);

        if (input_text == "&nbsp;") {
            // toast.warning("Please enter valid text");
            return;
        }
        else {

            //fetch fresh messages from server
            const currentChatroom = await fetchMessages();

            console.log("currentChatroom_currentChatroom ",currentChatroom)

            let lastMessages = [];

            if (currentChatroom.hasOwnProperty("messages")) {
                lastMessages = currentChatroom.messages;
            }


            // //  ===== Get a key for a new Post. ===== //
            // const newPostKey = push(ref(firebase_db)).key;
            // // console.log(newPostKey + " newPostKey");

            // set(ref(firebase_db, `/user_chats/${ActiveConversation.room_id}/message_list/` + newPostKey), {
            //     key: newPostKey,
            //     sender_id: userData.id,
            //     receiver_id: ActiveConversation.receiver_id,
            //     text: input_text,
            //     seen: false,
            //     createdAt: serverTimestamp()
            // });


            update(ref(firebase_db, `chatrooms/${ActiveConversation.room_id}`), {
                messages: [
                    ...lastMessages,
                    {
                        sender_id: userData.id,
                        receiver_id: ActiveConversation.receiver_id,
                        text: input_text,
                        seen: false,
                        createdAt: serverTimestamp()
                    },
                ],
            });


            // getMessages();
        }

    }

    const UserReqChange = async (status) => {

        /////// firebase update //////////////
        const updates = {};
        updates[`/user_chats/${ActiveConversation.room_id}/status`] = status;
        updates[`/user_chats/${ActiveConversation.room_id}/created_at`] = serverTimestamp();
        update(ref(firebase_db), updates);


        let new_data = {
            room_id: ActiveConversation.room_id,
            status: status
        };
        //  /////////////////// Api call ///////////////////////
        var responce = await new ApiService().POST(`/update-chat-request`, new_data);

        if (responce.data.status === "1") {
            // ////////////////// sidebar data set ///////////
            /////////////////// Api call ///////////////////////
            var responce = await new ApiService().GET(`/get-chat-request?sender_id=${userData.id}&status=pending,accept,reject`);

            if (responce.data.status === "1") {
                setSidebarList(responce.data.data)

                // if(status==="reject"){

                // }
            }
        }
    }


    const chkSelectChatPlan = async () => {
        setLoader(true);
        /////////////////// Api call ///////////////////////
        var response = await new ApiService().GET(`/check-user-chat-plan-status/${userData.id}`);
        console.log("check-user-chat-plan-status ", response.data);

        if (response.data.status !== '1') {
            toast.warning("Please Buy Chat Plan");
            setLoader(false);
            navigate("/profile");
        }

    }

    const ExpireNow = (unixTimestamp) => {

        const expirationDate = moment.unix(unixTimestamp);
        // const difference = moment.duration(expirationDate.diff(moment()));
        // const formattedDifference = moment.duration(difference).humanize();

        const currentDate = moment();


        if (expirationDate.isBefore(currentDate)) {
            return "chat subscription has expired.";
        }
        else {
            // const remainingDays = expirationDate.diff(currentDate, 'days').humanize();
            // return remainingDays;

            const durationUntilExpiration = moment.duration(expirationDate.diff(moment()));
            const humanizedExpiration = durationUntilExpiration.humanize(true);
            return humanizedExpiration;
        }

    }

    useEffect(() => {
        if (sidebarVisible) {
            setSidebarStyle({
                display: "flex",
                flexBasis: "auto",
                width: "100%",
                maxWidth: "100%"
            });
            setConversationContentStyle({
                display: "flex"
            });
            setConversationAvatarStyle({
                marginRight: "1em"
            });
            setChatContainerStyle({
                display: "none"
            });
        } else {
            setSidebarStyle({});
            setConversationContentStyle({});
            setConversationAvatarStyle({});
            setChatContainerStyle({});
        }
    }, [sidebarVisible, setSidebarVisible, setConversationContentStyle, setConversationAvatarStyle, setSidebarStyle, setChatContainerStyle]);


    // //////////////// First chat select //////////////////////////
    useEffect(() => {
        FirstLoadAndSelect();
    }, [])

    // //////////////// Sidebar Auto Load //////////////////////////
    useEffect(() => {
        const starCountRef = ref(firebase_db, `/chatrooms`);
        var unsubscribe = onValue(starCountRef, (snapshot) => {

            if (snapshot.exists()) {
                SidebarAutoLoad();
            }
        });

        return () => unsubscribe();
    }, [])


    // /////////////// Firebase to message fetch ///////////
    useEffect(() => {

        const starCountRef = ref(firebase_db, `/chatrooms/${ActiveConversation.room_id}`);
        var unsubscribe = onValue(starCountRef, (snapshot) => {

            if (snapshot.exists()) {

                const snap_data = snapshot.val();
                // console.log(snap_data)

                setMsgLoading(true);


                // //////////// message list get ////////////////////
                get(child(ref(firebase_db), `/chatrooms/${ActiveConversation.room_id}/messages`)).then(async (snapshot) => {
                    var tempArr = [];

                    if (snapshot.exists()) {

                        var messageData = snapshot.val();
                        // console.log(messageData, " messageData")

                        for (const [index, data] of messageData.entries()) {

                            ////////// update old message //////////
                            if (data.receiver_id == userData.id) {
                                if (data.seen == false) {
                                    update(ref(firebase_db, `chatrooms/${ActiveConversation.room_id}/messages/${index}`), {
                                        seen: true
                                    });
                                }
                            }
                            data.seen = (data.receiver_id === userData.id && ((data.seen === false) && true));
                            tempArr.push(data);

                        }


                    } else {
                        console.log("No data available!");
                    }
                    console.log("tempArr ", tempArr)
                    setMessageInputDisable(false);

                    setMessages(tempArr);

                }).catch((error) => {
                    console.error(error);
                });


                setMsgLoading(false);

            }

        });


        // const unsubscribe2 = get(child(ref(firebase_db), `/user_chats/${ActiveConversation.room_id}`)).then((snapshot) => {
        //     if (snapshot.exists()) {
        //         console.log(snapshot.val());
        //     } else {
        //         console.log("No data available");
        //     }
        // }).catch((error) => {
        //     console.error(error);
        // });



        return () => Object.keys(ActiveConversation).length !== 0 && unsubscribe();

    }, [ActiveConversation, userData.id])

    return (<>

        {/* ==== Loader ==== */}
        <PreLoader Loader={Loader} />
        {/* ==== Loader ==== */}

        <div className="chatUi">
            <MainContainer responsive>

                {/* /////////////////// Sidebar ///////////////// */}
                <Sidebar position="left" scrollable={false} style={sidebarStyle}>
                    {/* <Search placeholder="Search..." /> */}
                    <ConversationList>

                        {SidebarList.map((item, index) => {

                            return <Conversation key={index} onClick={() => handleConversationClick(item)}
                                name={item.name}
                                active={ActiveConversation?.receiver_id === item.receiver_id}
                                info={(item.seen == false) && item.text}
                                lastActivityTime={(item.seen == false) && moment.utc(item.createdAt).local().fromNow()}
                                unreadCnt={(item.seen == false) && item.msg_count}
                            >
                                <Avatar
                                    src={item.profile_pic_pro_resize_200_url}
                                    status={item.login_status === "1" ? "available" : "unavailable"}
                                    style={conversationAvatarStyle}
                                />
                                <Conversation.Content style={conversationContentStyle} />
                            </Conversation>

                        })}

                        {SidebarList.length === 0 && <>
                            <Conversation key={"1"}>
                                <Conversation.Content
                                    name={"No Chat List Found..."}
                                    className="no_chat_list text-center" />
                            </Conversation>
                        </>}

                    </ConversationList>
                </Sidebar>

                {/* //////////////// chat panel ///////////////////// */}
                <ChatContainer style={chatContainerStyle}>
                    <ConversationHeader>
                        <ConversationHeader.Back onClick={handleBackClick} />

                        {(Object.keys(ActiveConversation).length !== 0) &&
                            <Avatar
                                src={ActiveConversation?.profile_pic_pro_resize_200_url}
                                name={ActiveConversation?.name}
                            />}

                        <ConversationHeader.Content
                            userName={ActiveConversation?.name}
                        // info="Active 10 mins ago"
                        />
                        <ConversationHeader.Actions>

                            {/* <VoiceCallButton />
                            <VideoCallButton /> */}

                            {/* <button type="button" className="chat-close" onClick={() => navigate(-1)}><i class="fa fa-times-circle-o" aria-hidden="true"></i></button> */}

                        </ConversationHeader.Actions>
                    </ConversationHeader>

                    {/* ////////////// message list show /////////////////////////// */}
                    {(Object.keys(ActiveConversation).length !== 0) &&
                        <MessageList loading={msgLoading}>
                            {/* /////////////// Message List //////////////////////// */}
                            {(messages.length > 0) && (!ShowConfirmAlert) && messages.map((item, index) =>
                                <>
                                    <Message
                                        key={index}
                                        model={{
                                            type: "html",
                                            direction: item.sender_id === userData.id ? "outgoing" : "incoming",
                                            position: "normal",
                                        }}
                                        payload={`<span class="custom_chat_msg">${item.text}</span><br><span class="custom_time_msg">${moment(item.createdAt).format('hh:mm A')}</span>`}
                                    />

                                </>
                            )}


                        </MessageList>}
                    {/* ////////////// message list show end /////////////////////////// */}



                    {/* //////////////////// Chat Package show /////////////////////// */}
                    {(Object.keys(ActiveConversation).length === 0) && <MessageList loading={msgLoading}>

                        <div className="packages">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <div className={`plan plan--accent`}>

                                        {/* ///////////// % off /////////////////////////// */}
                                        {/* {SelectPackages?.chat_data?.validity === "weekly" &&
                                            <p className='active_btn'> 100%</p>
                                        } */}

                                        {SelectPackages?.chat_data?.validity === "monthly" &&
                                            <p className='active_btn'> 25%</p>
                                        }

                                        {SelectPackages?.chat_data?.validity === "quarterly" &&

                                            <p className='active_btn'> 50%</p>
                                        }

                                        {SelectPackages?.chat_data?.validity === "yearly" &&

                                            <p className='active_btn'> 75%</p>
                                        }
                                        {/* ///////////// end % off /////////////////////////// */}

                                        <h2 className="plan-title">{SelectPackages?.chat_data?.plan_name}</h2>

                                        {/* ///////////// price /////////////////////////// */}
                                        {SelectPackages?.chat_data?.validity === "monthly" &&

                                            <p className="plan-price">
                                                <del>${(39 * 4).toFixed(2)}/ {SelectPackages?.chat_data?.validity}</del>
                                            </p>
                                        }

                                        {SelectPackages?.chat_data?.validity === "quarterly" &&

                                            <p className="plan-price">
                                                <del>${(39 * 12).toFixed(2)}/ {SelectPackages?.chat_data?.validity}</del>
                                            </p>
                                        }

                                        {SelectPackages?.chat_data?.validity === "yearly" &&

                                            <p className="plan-price">
                                                <del>${(39 * 52).toFixed(2)}/ {SelectPackages?.chat_data?.validity}</del>
                                            </p>
                                        }
                                        {/* ///////////// end price /////////////////////////// */}

                                        <p className="plan-price">
                                            ${Number(SelectPackages?.chat_data?.amount)}<span>/ {SelectPackages?.chat_data?.validity}</span>
                                        </p>

                                        <ul>
                                            <li>
                                                <img
                                                    src="/images/inner-page-img/greater-than-symbol.png"
                                                    alt="grater"
                                                />{" "}
                                                Starts chatting with Users
                                            </li>
                                            {/* <li>
                                                <img
                                                    src="/images/inner-page-img/greater-than-symbol.png"
                                                    alt="grater"
                                                />{" "}
                                                Full week free
                                            </li> */}
                                        </ul>


                                        <div className=''>
                                            <span className='expire_date'> Start On: {moment.unix(SelectPackages?.start_time).format("MM/DD/YYYY")}</span>
                                        </div>

                                        <div className=''>
                                            <span className='expire_date'> Expires On: {moment.unix(SelectPackages?.expire_time).format("MM/DD/YYYY")}</span>
                                        </div>

                                        <div className=''>
                                            <span className='expire_date'> Expires/ Countdown: {ExpireNow(SelectPackages?.expire_time)}</span>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </MessageList>}
                    {/* //////////////////// Chat Package show End /////////////////// */}


                    {(Object.keys(ActiveConversation).length !== 0) &&
                        <MessageInput disabled={MessageInputDisable} attachButton={false} placeholder="Type message here" onSend={sendMessage} />
                    }

                </ChatContainer>

            </MainContainer>
        </div>
    </>
    );
};

export default Chat;
